import Vue from 'vue';
import App from './app.vue';
import store from "./store";
import 'document-register-element/build/document-register-element';
import VueScrollTo from 'vue-scrollto';
import VCalendar from 'v-calendar';
import VModal from 'vue-js-modal';
import VueRouter from 'vue-router';

import ApiService from "./common/api.service";
ApiService.init();

import vueCustomElement from 'vue-custom-element';

Vue.use(vueCustomElement);

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value + ':-';
  }
  var formattedString = value.toLocaleString('se-Se') + ':-';
  return formattedString.replace(',', ' ');
});

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

Vue.use(VCalendar, {
  componentPrefix: 'vc'
});
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.config.productionTip = false;
Vue.use(VueRouter);
App.store = store;

App.router = new VueRouter({
  mode: 'history',
});

import 'leaflet/dist/leaflet.css';

Vue.customElement('bokahem-plugin', App);

Vue.mixin({
  methods: {
    capitalizeFirstLetter: str => str && str.length > 0 ? str.charAt(0).toLocaleUpperCase() + str.slice(1) : str,
    lowFirstLetter: str => str && str.length > 0 ? str.charAt(0).toLocaleLowerCase()  + str.slice(1) : str,
    removeBackspace: str => str && str.length > 0 ? str.replace(' ', '') : str,
    removeAndspace: str => str && str.length > 0 ? str.replace('&', '') : str,
    replaceBackspace: str => str && str.length > 0 ? str.replace(' ', '_') : str,
    replaceBackspaceDef: str => str && str.length > 0 ? str.replace(' ', '-') : str,
    fullStrToLower: str => str && str.length > 0 ? str.toLocaleLowerCase() : str,
    formatPostalCode: str => str && str.length > 0 ? str.slice(0,2) + ' ' + str.slice(3,4) : str,
   // mediaClasses: addMediaClassToWidgetInit(),
  }
});

Vue.use(VueScrollTo);

document.body.addEventListener('click', e => {

  //let elements = document.getElementsByClassName('select_active');
  //removeClass(elements, 'select_active');

  if (e.target.className != 'select-current'
      && e.target.className != 'select-js'
      && e.target.className != 'select__current-js'
      && e.target.className != 'select select-js border-rounded-bokahem--right'
      && e.target.className != 'select-heading'
      && e.target.className != 'select-current bokahem-valid-error-no-padding'
      && e.target.className != 'select border-rounded-bokahem select-js'
      && e.target.className != 'select border-left--none border-right--none select-js') {

    let elements = document.getElementsByClassName('select_active');
    removeClass(elements, 'select_active');
  } else {

    let parentElement = e.target.parentElement;
    let highParentElement = parentElement.parentElement;

    if (highParentElement.classList && !highParentElement.classList.contains('select_active')) {
      //  highParentElement.classList.add('select_active');
    } else if (e.target.classList && e.target.classList.contains('select_active')) {
      //  highParentElement.classList.remove('select_active');
    }
  }
});

function removeClass(elements, className) {
  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }
}

/*
new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
 */