<template>
  <div class="col-bokahem-3 col-bokahem-sm-2 col-bokahem-md-2 col-bokahem-xl-2 input-wrap-col-bokahem">
    <div style="height: 14px; font-size:12px !important;padding-left:5px;">
      <span>{{  kvmSubtitle }}</span>
    </div>
    <div class="input-wrap header-bokahem-block">
      <svg class="icon-svg-bokahem">
        <HouseIcon></HouseIcon>
      </svg>
      <input 
        v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" 
        :class="{'bokahem-landing': !squareMetersValidation}" 
        autocomplete="nope"
        ref="serviceparameter" type="text" :placeholder="kvmPlaceholder" class="input-element-bokahem border-rounded-bokahem--right header-input" v-model="kvm">
    </div>
  </div>
</template>

<script>
import {
  SET_KVM, SET_HEADER_ACTIVE
} from "@/store/mutations.type";
import HouseIcon from '@/assets/custom-svg/icons/house.svg';

export default {
  components: {
    HouseIcon
  },
  data() {
    return {
      squareMetersValidation: true
    }
  },
  methods: {
    validateKvm(value) {

      const regexSqr = /^[0-9]*/gm;
      const matches = regexSqr.exec(value);

      this.squareMetersValidation = true;

      if (matches === null) {
        this.squareMetersValidation = false;
        this.$refs.serviceparameter.focus();
      } else {
        if (matches[0] !== matches['input']) {
          this.squareMetersValidation = false;
          this.$refs.serviceparameter.focus();
        }
      }

      if (value === null || value === '' || value === 0) {
        this.squareMetersValidation = false;
      }
    }
  },
  watch: {
    '$store.state.searchButtonClicked': function() {
        this.validateKvm(this.$store.state.kvm);
    }
  },
  computed: {
    backgroundPrimaryColor: {
      get () {
        return this.$store.getters.backgroundPrimaryColor;
      }
    },
    searchButtonClicked: {
      get() {
        return this.$store.getters.searchButtonClicked;
      }
    },
    kvmSubtitle: {
      get() {
        if ('kvm_subtitle' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.kvm_subtitle;
        } else {
          return null;
        }
      }
    },
    kvmPlaceholder: {
      get() {
        if ('kvm_placeholder' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.kvm_placeholder;
        } else {
          return null;
        }
      }
    },
    kvm: {
      get () {
        return this.$store.state.kvm;
      },
      set (value) {
        this.$store.dispatch(SET_HEADER_ACTIVE, true);
        this.validateKvm(value);
        this.$store.dispatch(SET_KVM, value);
      }
    }
  },
}
</script>
