<template>
  <div v-if="interestFormEnabled" v-bind:style="{ color: textColor + ' !important'}">

    <div v-if="checkGaps == false && fetchGapEffort > 0" v-show="isLoading === false"
         class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 text-center-bokahem">
      Vi har tyvärr inga bokningsbara tider att presentera denna vecka.
      <p>Oftast så löser vi det ändå, fyll gärna i nedan fält så kontaktar vi dig inom kort.</p>
    </div>

    <div v-if="isLoading === false" class="bokahem-green-color-text col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
          <span v-bind:style="{ color: fontColor + ' !important'}" style="cursor:pointer;" class="mt-1" @click="showInterestForm">
            {{ interestFormTitle }}
          </span>
    </div>

    <div class="calendar-bokahem mb-4" v-if="interestForm || (checkGaps == false && fetchGapEffort > 0)"
         v-show="isLoading === false">
      <InterestForm></InterestForm>
    </div>

  </div>
</template>

<script>

import InterestForm from "@/components/InterestForm";
import {INTEREST_FORM_IS_SENT} from "@/store/mutations.type";

export default {
  name: 'App',
  components: {
    InterestForm
  },
  methods: {
    showInterestForm() {
      this.interestForm = this.interestForm === false ? true : false;
      this.$store.dispatch(INTEREST_FORM_IS_SENT, false);
    }
  },
  data() {
    return {
      interestForm: false
    }
  },
  computed: {
    selectedGap: {
      get() {
        return this.$store.getters.selectedGap;
      }
    },
    isLoading: {
      get() {
        return this.$store.getters.isLoading;
      }
    },
    checkGaps: {
      get() {
        return this.$store.getters.checkGaps;
      }
    },
    fetchGapEffort: {
      get() {
        return this.$store.getters.fetchGapEffort;
      }
    },
    interestFormEnabled: {
      get () {
        if ('show_price' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.show_price;
        } else {
          return false;
        }
      }
    },
    interestFormTitle: {
      get() {
        if ('interest_form_title' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.interest_form_title;
        } else {
          return 'Har du andra önskemål? Kontakta oss gärna >';
        }
      }
    },
    fontColor: {
      get() {
          return this.$store.getters.primaryColor;
      }
    }
  }
}
</script>
