<template>
  <div>
      <button
            @mouseover="hover = true"
            @mouseleave="hover = false"
            :class="{ 'btn-hover': hover }"
            v-bind:style="{
            'background-color': buttonColor + ' !important', 'margin-top':'13px'}"
            @click="getGaps()"
            class="btn-bokahem-search-button btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem h-bokahem-100">
        {{ buttonText }}
      </button>
  </div>
</template>

<script>

import {
  FETCH_GAPS,
  FETCH_PLACE,
  FETCH_SUPPLIERS
} from "@/store/actions.type";
import {SET_HEADER_ACTIVE, SET_SEARCH_BUTTON_CLICKED} from "@/store/mutations.type";

export default {
  methods: {
    getGaps() {

      this.$store.dispatch(SET_SEARCH_BUTTON_CLICKED);

      if (this.$store.getters.selectedRecurrenceOption &&
          'widget_origin' in this.$store.getters.pluginSettings
          && this.$store.getters.postalCode
          && this.$store.getters.kvm
          && this.$store.getters.selectedService) {

        let params = {};
        let serviceParameter = {};

        if (this.$store.getters.serviceGroupId == 2) {
          serviceParameter['bathroom'] = this.$store.getters.bathroom;
          if (this.$store.getters.kitchen === true) {
            serviceParameter['kitchen'] = 1;
          } else {
            serviceParameter['kitchen'] = 0;
          }
        }

        if (this.$store.getters.additionalParameter == true) {

          if (this.$store.getters.bars === true) {
            serviceParameter['bars'] = 1;
          } else {
            serviceParameter['bars'] = 0;
          }

          if (this.$store.getters.frames === true) {
            serviceParameter['frames'] = 1;
          } else {
            serviceParameter['frames'] = 0;
          }

          if (this.$store.getters.balcony === true) {
            serviceParameter['balcony'] = 1;
          } else {
            serviceParameter['balcony'] = 0;
          }

          if (this.$store.getters.ladder === true) {
            serviceParameter['ladder'] = 1;
          } else {
            serviceParameter['ladder'] = 0;
          }

          if (this.$store.getters.fourSides === true) {
            serviceParameter['4sides'] = 1;
          } else {
            serviceParameter['4sides'] = 0;
          }

          serviceParameter['extra_windows'] = this.$store.getters.extraWindows;

          if (this.$store.getters.extraWindows > 0) {
            serviceParameter['is_extra_windows'] = 1;
          } else {
            serviceParameter['is_extra_windows'] = 0;
          }
        }

        serviceParameter['square_meters'] = this.$store.getters.kvm;

        params['service_id'] = this.$store.getters.selectedServiceId;
        params['postal_code'] = this.$store.getters.postalCode;
        params['service_parameters'] = serviceParameter;
        params['service_category_id'] = this.$store.getters.serviceCategoryId;
        params['service_group_id'] = this.$store.getters.serviceGroupId;
        params['supplier_id'] = this.$store.getters.supplierId;
        params['cleanEffort'] = true;

        params['starting_date'] = this.$store.getters.startingDate;

        //   params['starting_date'] = new Date();

        this.$store.dispatch(FETCH_GAPS, params).then(() => {
          this.$scrollTo('#gapcalendar');
          this.$store.dispatch(SET_HEADER_ACTIVE, false);
        });

        this.$store.dispatch(FETCH_SUPPLIERS, params);


        let placeParams = {};
        placeParams['postal_code'] = this.$store.getters.postalCode;
        placeParams['service_category_id'] = this.$store.getters.serviceCategoryId;

        this.$store.dispatch(FETCH_PLACE, placeParams);

      }
    },
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    status: {
      get() {
        if (this.$store.getters.selectedRecurrenceOption &&
            'widget_origin' in this.$store.getters.pluginSettings
            && this.$store.getters.postalCode
            && this.$store.getters.kvm
            && this.$store.getters.selectedService) {
          return true;
        } else {
          return false;
        }
      }
    },
    buttonColor: {
      get () {
        return this.$store.getters.primaryMediumColor;
      }
    },
    buttonText: {
      get () {
        if ('search_button_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.search_button_text;
        } else {
          return 'Se ditt pris och boka';
        }
      }
    }
  }
}
</script>