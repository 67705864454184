<template>
  <div class="bokahem-green-color-text col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
    <div style="height: 21px;" v-if="!postalCode && serviceClicked === false">
    </div>
    <h3 class="bokahem-h3" v-bind:style="{ color: fontColorNoValid + ' !important'}" v-if="!postalCode && serviceClicked === true">
      {{ postalCodeServiceClickedText }}
    </h3>
    <h3 class="bokahem-h3" v-bind:style="{ color: fontColorNoValid + ' !important'}" v-if="postalCodeValid === false && postalCode">
      {{ postalCodeValidationText }}
    </h3>
    <h3 class="bokahem-h3" v-bind:style="{ color: fontColorNo + ' !important'}" v-if="postalCodeValid === true && postalCode && postalCodeShow === false">
      {{ textPostalCodeNo }}
    </h3>
    <h3 class="bokahem-h3" v-bind:style="{ color:fontColor + ' !important'}" v-if="postalCodeValid === true && postalCode && postalCodeShow">
      {{ textPostalCodeYes }}
    </h3>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      serviceClicked: false
    }
  },
  watch: {
    '$store.state.serviceClicked': function() {
      this.serviceClicked = this.$store.state.serviceClicked;
    }
  },
  computed: {
    postalCodeShow: {
      get() {
        return this.$store.getters.postalCodeShow;
      }
    },
    postalCodeValidationText: {
      get() {
        if ('postal_code_validation_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.postal_code_validation_text;
        } else {
          return null;
        }
      }
    },
    textPostalCodeNo: {
      get () {
        if ('text_postal_code_no' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.text_postal_code_no;
        } else {
          return null;
        }
      }
    },
    postalCodeValid: {
      get () {
        return this.$store.getters.postalCodeValid
      }
    },
    supplierServices: {
      get () {
        return this.$store.getters.supplierServices
      }
    },
    postalCode: {
      get () {
        return this.$store.getters.postalCode;
      }
    },
    textPostalCodeYes: {
      get () {
        if ('1' in this.$store.getters.supplierServices) {
          return this.$store.getters.supplierServices[1].message;
        } else {
          return null;
        }
      }
    },
    postalCodeServiceClickedText: {
      get () {
        if ('postal_code_service_clicked_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.postal_code_service_clicked_text;
        } else {
          return null;
        }
      }
    },
    fontColor: {
      get() {
        return this.$store.getters.primaryColor;
      }
    },
    fontColorNo: {
      get() {
        return this.$store.getters.validationColor;
      }
    },
    fontColorNoValid: {
      get() {
        return this.$store.getters.validationColor;
      }
    }
  }
}
</script>