<template>
  <div>
      <button
            @mouseover="hover = true"
            @mouseleave="hover = false"
            :class="{ 'btn-hover': hover }"
            v-bind:style="{
            'background-color': buttonColor + ' !important', 'margin-top':'13px'}"
            @click="goToWidget()"
            class="btn-bokahem-search-button btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem h-bokahem-100">
        {{ buttonText }}
      </button>
  </div>
</template>

<script>

import {SET_SEARCH_BUTTON_CLICKED} from "@/store/mutations.type";

export default {
  methods: {
    goToWidget() {

      this.$store.dispatch(SET_SEARCH_BUTTON_CLICKED);

      if (this.$store.getters.selectedRecurrenceOption &&
          'widget_origin' in this.$store.getters.pluginSettings
          && this.$store.getters.postalCode
          && this.$store.getters.kvm
          && this.$store.getters.selectedService) {

        let postalCode = this.$store.getters.postalCode.replace(' ', '');

        postalCode = '' + postalCode + '';
        postalCode = postalCode.replace(' ', '');

        let additionalParameters = '';

        if (this.$store.getters.additionalParameter == true) {
          if (this.$store.getters.bars === true) {
            additionalParameters += '&bars=1';
          } else {
            additionalParameters += '&bars=0';
          }

          if (this.$store.getters.fourSides === true) {
            additionalParameters += '&sides=1';
          } else {
            additionalParameters += '&sides=0';
          }

          if (this.$store.getters.frames === true) {
            additionalParameters += '&frames=1';
          } else {
            additionalParameters += '&frames=0';
          }

          if (this.$store.getters.balcony === true) {
            additionalParameters += '&balcony=1';
          } else {
            additionalParameters += '&balcony=0';
          }

          if (this.$store.getters.ladder === true) {
            additionalParameters += '&ladder=1';
          } else {
            additionalParameters += '&ladder=0';
          }

          if (this.$store.getters.extraWindows !== null) {
            additionalParameters += '&extra=' + this.$store.getters.extraWindows;
          } else {
            additionalParameters += '&extra=0';
          }
        }

        window.location.href = 'https://' + this.$store.getters.pluginSettings.widget_origin
            + '/' + this.$store.getters.selectedService
            + '/' + this.$store.getters.pluginSettings.supplier_prefix
            + '?animals=' + this.$store.getters.excludeTag
            + '&postnr=' + postalCode + '&kvm=' + this.$store.getters.kvm
            + '&recurrence=' + this.$store.getters.selectedRecurrenceOption
            + '&sid=' + this.$store.getters.pluginSettings.session_id
            + additionalParameters;
      }
    },
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    status: {
      get() {
        if (this.$store.getters.selectedRecurrenceOption &&
            'widget_origin' in this.$store.getters.pluginSettings
            && this.$store.getters.postalCode
            && this.$store.getters.kvm
            && this.$store.getters.selectedService) {
          return true;
        } else {
          return false;
        }
      }
    },
    buttonColor: {
      get () {
        return this.$store.getters.primaryMediumColor;
      }
    },
    buttonText: {
      get () {
        if ('search_button_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.search_button_text;
        } else {
          return 'Se ditt pris och boka';
        }
      }
    }
  }
}
</script>