
const classHelper = {
  fitPluginForParentBlock() {
    var widget = document.getElementById('widget');
    var widgetParent = widget.parentElement;
    var higherParentBlock = widgetParent.parentElement;
    var widgetChild = widget.children[0];
    this.addMediaClassToWidget(higherParentBlock, widgetChild);
  },
  addMediaClassToWidget(widgetParent, widgetChild) {
    if (widgetParent) {
      var widgetParentWidth = widgetParent.offsetWidth;

      if (widgetParentWidth > 320 && widgetParentWidth < 550) {
        widgetChild.classList.remove('media-560');
        widgetChild.classList.remove('media-768');
        widgetChild.classList.remove('media-1024');
        widgetChild.classList.remove('media-1200');
      } else if (widgetParentWidth >= 550 && widgetParentWidth < 768) {
        widgetChild.classList.remove('media-768');
        widgetChild.classList.remove('media-1024');
        widgetChild.classList.remove('media-1200');
        widgetChild.classList.add('media-560');
      } else if (widgetParentWidth >= 768 && widgetParentWidth < 1024) {
        widgetChild.classList.remove('media-1024');
        widgetChild.classList.remove('media-1200');
        widgetChild.classList.add('media-560');
        widgetChild.classList.add('media-768');
      } else if (widgetParentWidth >= 1024 && widgetParentWidth < 1200) {
        widgetChild.classList.remove('media-1200');
        widgetChild.classList.add('media-560');
        widgetChild.classList.add('media-768');
        widgetChild.classList.add('media-1024');
      } else if (widgetParentWidth >= 1200) {
        widgetChild.classList.add('media-560');
        widgetChild.classList.add('media-768');
        widgetChild.classList.add('media-1024');
        widgetChild.classList.add('media-1200');
      }

      if (widgetParentWidth > 320 && widgetParentWidth < 330) {
        widgetChild.classList.remove('media-font-330');
        widgetChild.classList.remove('media-font-340');
        widgetChild.classList.remove('media-font-350');
        widgetChild.classList.remove('media-font-361');
        widgetChild.classList.remove('media-font-370');
        widgetChild.classList.remove('media-font-380');
        widgetChild.classList.remove('media-font-390');
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList = '';
      } else if (widgetParentWidth >= 330 && widgetParentWidth < 340) {
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.remove('media-font-340');
        widgetChild.classList.remove('media-font-350');
        widgetChild.classList.remove('media-font-361');
        widgetChild.classList.remove('media-font-370');
        widgetChild.classList.remove('media-font-380');
        widgetChild.classList.remove('media-font-390');
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
      } else if (widgetParentWidth >= 340 && widgetParentWidth < 350) {
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.remove('media-font-350');
        widgetChild.classList.remove('media-font-361');
        widgetChild.classList.remove('media-font-370');
        widgetChild.classList.remove('media-font-380');
        widgetChild.classList.remove('media-font-390');
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
      } else if (widgetParentWidth >= 350 && widgetParentWidth < 361) {
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.remove('media-font-361');
        widgetChild.classList.remove('media-font-370');
        widgetChild.classList.remove('media-font-380');
        widgetChild.classList.remove('media-font-390');
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
      } else if (widgetParentWidth >= 361 && widgetParentWidth < 370) {
        widgetChild.classList.remove('media-font-370');
        widgetChild.classList.remove('media-font-380');
        widgetChild.classList.remove('media-font-390');
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
      } else if (widgetParentWidth >= 370 && widgetParentWidth < 380) {
        widgetChild.classList.remove('media-font-380');
        widgetChild.classList.remove('media-font-390');
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
      } else if (widgetParentWidth >= 380 && widgetParentWidth < 390) {
        widgetChild.classList.remove('media-font-390');
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
      } else if (widgetParentWidth >= 390 && widgetParentWidth < 400) {
        widgetChild.classList.remove('media-font-400');
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
      } else if (widgetParentWidth >= 400 && widgetParentWidth < 450) {
        widgetChild.classList.remove('media-font-450');
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
      } else if (widgetParentWidth >= 450 && widgetParentWidth < 500) {
        widgetChild.classList.remove('media-font-500');
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
      } else if (widgetParentWidth >= 500 && widgetParentWidth < 550) {
        widgetChild.classList.remove('media-font-550');
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-500');
      } else if (widgetParentWidth >= 550 && widgetParentWidth < 600) {
        widgetChild.classList.remove('media-font-600');
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
      } else if (widgetParentWidth >= 600 && widgetParentWidth < 650) {
        widgetChild.classList.remove('media-font-650');
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
        widgetChild.classList.add('media-font-600');
      } else if (widgetParentWidth >= 650 && widgetParentWidth < 700) {
        widgetChild.classList.remove('media-font-700');
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
        widgetChild.classList.add('media-font-600');
        widgetChild.classList.add('media-font-650');
      } else if (widgetParentWidth >= 700 && widgetParentWidth < 768) {
        widgetChild.classList.remove('media-font-768');
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
        widgetChild.classList.add('media-font-600');
        widgetChild.classList.add('media-font-650');
        widgetChild.classList.add('media-font-700');
      } else if (widgetParentWidth >= 768 && widgetParentWidth < 860) {
        widgetChild.classList.remove('media-font-860');
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
        widgetChild.classList.add('media-font-600');
        widgetChild.classList.add('media-font-650');
        widgetChild.classList.add('media-font-700');
        widgetChild.classList.add('media-font-768');
      } else if (widgetParentWidth >= 860 && widgetParentWidth < 1024) {
        widgetChild.classList.remove('media-font-1024');
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
        widgetChild.classList.add('media-font-600');
        widgetChild.classList.add('media-font-650');
        widgetChild.classList.add('media-font-700');
        widgetChild.classList.add('media-font-768');
        widgetChild.classList.add('media-font-860');
      } else if (widgetParentWidth >= 1024 && widgetParentWidth < 1200) {
        widgetChild.classList.remove('media-font-1200');
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
        widgetChild.classList.add('media-font-600');
        widgetChild.classList.add('media-font-650');
        widgetChild.classList.add('media-font-700');
        widgetChild.classList.add('media-font-768');
        widgetChild.classList.add('media-font-860');
        widgetChild.classList.add('media-font-1024');
      } else if (widgetParentWidth >= 1200) {
        widgetChild.classList.add('media-font-330');
        widgetChild.classList.add('media-font-340');
        widgetChild.classList.add('media-font-350');
        widgetChild.classList.add('media-font-361');
        widgetChild.classList.add('media-font-370');
        widgetChild.classList.add('media-font-380');
        widgetChild.classList.add('media-font-390');
        widgetChild.classList.add('media-font-400');
        widgetChild.classList.add('media-font-450');
        widgetChild.classList.add('media-font-550');
        widgetChild.classList.add('media-font-600');
        widgetChild.classList.add('media-font-650');
        widgetChild.classList.add('media-font-700');
        widgetChild.classList.add('media-font-768');
        widgetChild.classList.add('media-font-860');
        widgetChild.classList.add('media-font-1024');
        widgetChild.classList.add('media-font-1200');
      }
    }
    
  }
}

export default classHelper;

