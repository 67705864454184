<template>
  <div  class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mb-1 text-center-bokahem"
        v-if="co === true"
        v-show="selectedGap && isLoading === false">
    <ul class="calendar-bokahem-list-icon">
      <li class="calendar-bokahem-list-icon-item">
        <svg class="icon-svg icon-co2 bokahem-main-icon-color-svg" style="background-color: unset !important;width: 45px; height: 45px; padding-top: 3px;">
          <CoIcon></CoIcon>
        </svg>
      </li>
      <li class="calendar-bokahem-list-icon-item">
        - Kort restid, vi är i ditt område.
      </li>
    </ul>
  </div>

</template>

<script>

import CoIcon from '@/assets/custom-svg/icons/co2-version2.svg';

export default {
  name: 'App',
  components: {
    CoIcon
  },
  computed: {
    co() {
      if ('best_gaps' in this.$store.getters.gaps) {
        if (this.$store.getters.gaps.best_gaps[0].discounts.travel.value != 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    selectedGap() {
      return this.$store.getters.selectedGap;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    }
  }
}

</script>