0;71;53M0;71;53m<template>

    <div style="display: inline-block" class="mb-1">
      <DatePicker class="inline-block h-full"
                  v-model="date"
                  :min-date="new Date()"
                  locale="sv" style="border: 0px !important;">

        <template v-slot="{ inputValue, togglePopover}">
          <div class="flex items-center" style="z-index:9999;">
          <span @click="togglePopover()" style="cursor: pointer;">
            Visa lediga tider från:
          </span>
            <span style="cursor: pointer;text-decoration: underline;"
                  @click="togglePopover()"
                  class="bokahem-main-green text-underline">
            {{ inputValue }}
          </span>
          </div>
        </template>
      </DatePicker>
    </div>

</template>

<script>
    import { DatePicker } from 'v-calendar';
    import {FETCH_GAPS} from "@/store/actions.type";
    import {SET_STARTING_DATE} from "@/store/mutations.type";
    export default {
      components: {
        DatePicker
      },
      data() {
        return {
          date: new Date()
        };
      },
      watch: {
        'date': function () {
          if (this.date === null) {
            this.date = new Date();
          }
//          this.$store.dispatch(SET_STARTING_DATE, this.date);
 
           this.$store.dispatch(SET_STARTING_DATE, this.date).then(() => {
            let params = {};

            let serviceParameter = {};

            if (this.$store.state.serviceGroupId == 2) {
                serviceParameter['bathroom'] = this.$store.state.bathroom;
                if (this.$store.state.kitchen === true) {
                  serviceParameter['kitchen'] = 1;
                } else {
                  serviceParameter['kitchen'] = 0;
                }
              }

            if (this.$store.state.additionalParameter == true) {

              if (this.$store.state.bars === true) {
                serviceParameter['bars'] = 1;
              } else {
                serviceParameter['bars'] = 0;
              }

              if (this.$store.state.frames === true) {
                serviceParameter['frames'] = 1;
              } else {
                serviceParameter['frames'] = 0;
              }

              if (this.$store.state.balcony === true) {
                serviceParameter['balcony'] = 1;
              } else {
                serviceParameter['balcony'] = 0;
              }

              if (this.$store.state.ladder === true) {
                serviceParameter['ladder'] = 1;
              } else {
                serviceParameter['ladder'] = 0;
              }

              if (this.$store.state.fourSides === true) {
                serviceParameter['4sides'] = 1;
              } else {
                serviceParameter['4sides'] = 0;
              }

              serviceParameter['extra_windows'] = this.$store.state.extraWindows;

              if (this.$store.state.extraWindows > 0) {
                serviceParameter['is_extra_windows'] = 1;
              } else {
                serviceParameter['is_extra_windows'] = 0;
              }
            }

              serviceParameter['square_meters'] = this.$store.state.kvm;

              params['service_id'] = this.$store.state.selectedServiceId;
              params['postal_code'] = this.$store.state.postalCode;
              params['service_parameters'] = serviceParameter;
              params['service_category_id'] = this.$store.state.serviceCategoryId;
              params['service_group_id'] = this.$store.state.serviceGroupId;

              params['supplier_id'] = this.$store.state.supplierId;
              params['cleanEffort'] = true;
              params['showSupplier'] = true;
              params['check_supplier'] = true;
              params['starting_date'] = this.$store.state.startingDate;
              params['time_filter'] = this.$store.state.eveningDay;

              this.$store.dispatch(FETCH_GAPS, params);
            });
       }
      },
      computed: {
        isLoading() {
          return this.$store.getters.isLoading;
        }
      }
    }
</script>
