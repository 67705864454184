<template>
  <div>
    <div class="row-bokahem">

      <div class="col-bokahem-12" v-if="additionalParameterTitleText">
        <div class="m-auto-bokahem mt-1 text-center-bokahem" v-bind:style="{ color: fontColor + ' !important'}">
          {{ additionalParameterTitleText }}
        </div>
      </div>

      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">
            Sidor som ska putsas:
          </label>
          <label class="common-checkbox-label-bokahem padding-right-15-bokahem"
                 :class="{'common-radio-label_active': fourSides == false }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setFourSides(false)">
                <span class="common-custom-radio_after">
                </span>
                  </span>
            2
          </label>
          <label class="common-checkbox-label-bokahem common-radio-label"
                 :class="{'common-radio-label_active': fourSides == true }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setFourSides(true)">
                  <span class="common-custom-radio_after"></span>
                  </span>
            4
          </label>
        </div>
      </div>


      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">
            Vi har fler än {{ windowQuantity }} fönster:
            <span class="additional-parameter-image-text" v-bind:style="{ color: fontColor + ' !important'}" @click="setShowInstruction()">
              Är du osäker hur vi räknar fönster?
            </span>
          </label>
          <label class="common-checkbox-label-bokahem"
                 :class="{'common-radio-label_active': isExtraWindows == false }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setIsExtraWindows(false)">
                <span class="common-custom-radio_after">
                </span>
                  </span>
            Nej
          </label>
          <label class="common-checkbox-label-bokahem common-radio-label"
                 :class="{'common-radio-label_active': isExtraWindows == true }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setIsExtraWindows(true)">
                  <span class="common-custom-radio_after"></span>
                  </span>
            Ja
          </label>
        </div>
      </div>

      <div class="col-bokahem-12">
      <div class="row-bokahem" v-show="showInstruction === true">
        <div class="col-bokahem-6 mb-2">
          <h4 class="bokahem-h4">1 st fönster</h4>
          <img style="width: 70%" src="https://plugin.bokahem.se/1.jpeg">
          <span><br></span>
        </div>
        <div class="col-bokahem-6 mb-2">
          <h4 class="bokahem-h4">2 st fönster</h4>
          <img style="width: 70%" src="https://plugin.bokahem.se/2.jpeg">
          <span class="text-black-bokahem" style="display: block;">(med spröjs)</span>
        </div>
        <div class="col-bokahem-6 mb-2">
          <h4 class="bokahem-h4">3 st fönster</h4>
          <img style="width: 70%" src="https://plugin.bokahem.se/3.jpeg">
        </div>
        <div class="col-bokahem-6 mb-2">
          <h4 class="bokahem-h4">4 fönster</h4>
          <img style="width: 70%" src="https://plugin.bokahem.se/4.jpeg">
        </div>
      </div>
        </div>

      <div class="col-bokahem-12" v-show="isExtraWindows == 1">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">
            Antal extra fönster:
          </label>
          <div class="info-input-holder-bokahem" style="width:100px;">
            <input type="number" style="font-size: 18.25px !important;"
                   v-model="extraWindows"
                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
          </div>
        </div>
      </div>

      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">Fönstren har spröjs:</label>
          <label class="common-checkbox-label-bokahem"
                 :class="{'common-radio-label_active': bars == false }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setBars(false)">
                <span class="common-custom-radio_after">
                </span>
                  </span>
            Nej
          </label>
          <label class="common-checkbox-label-bokahem common-radio-label"
                 :class="{'common-radio-label_active': bars == true }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setBars(true)">
                  <span class="common-custom-radio_after"></span>
                  </span>
            Ja
          </label>
        </div>
      </div>

      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">Tvätta fönsterkarmar:</label>
          <label class="common-checkbox-label-bokahem"
                 :class="{'common-radio-label_active': frames == false }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setFrames(false)">
                <span class="common-custom-radio_after">
                </span>
                  </span>
            Nej
          </label>
          <label class="common-checkbox-label-bokahem common-radio-label"
                 :class="{'common-radio-label_active': frames == true }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setFrames(true)">
                  <span class="common-custom-radio_after"></span>
                  </span>
            Ja
          </label>
        </div>
      </div>

      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">
            Inglasad balkong/terrass:
          </label>
          <label class="common-checkbox-label-bokahem"
                 :class="{'common-radio-label_active': balcony == false }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setBalcony(false)">
                <span class="common-custom-radio_after">
                </span>
                  </span>
            Nej
          </label>
          <label class="common-checkbox-label-bokahem common-radio-label"
                 :class="{'common-radio-label_active': balcony == true }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setBalcony(true)">
                  <span class="common-custom-radio_after"></span>
                  </span>
            Ja
          </label>
        </div>
      </div>


      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">Stege behövs:</label>
          <label class="common-checkbox-label-bokahem"
                 :class="{'common-radio-label_active': ladder == false }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setLadder(false)">
                <span class="common-custom-radio_after">
                </span>
                  </span>
            Nej
          </label>
          <label class="common-checkbox-label-bokahem common-radio-label"
                 :class="{'common-radio-label_active': ladder == true }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setLadder(true)">
                  <span class="common-custom-radio_after"></span>
                  </span>
            Ja
          </label>
        </div>
      </div>



    </div>
  </div>
</template>

<script>

import {
  SET_EXTRA_WINDOWS,
  SET_FOUR_SIDES,
  SET_BALCONY,
  SET_BARS,
  SET_LADDER,
  SET_FRAMES
} from "@/store/mutations.type";

import {
  FETCH_WINDOW_QUANTITY
} from "@/store/actions.type";

export default {
  name: 'App',
  data() {
    return {
      fourSides: false,
      extraWindows: false,
      isExtraWindows: false,
      balcony: false,
      ladder: false,
      bars: false,
      frames: false,
      showInstruction: false,
    }
  },
  watch: {
    'extraWindows': function() {
      this.$store.dispatch(SET_EXTRA_WINDOWS, this.extraWindows);
    },
    '$store.state.kvm': function() {
      if (this.$store.state.kvm != null && this.$store.state.kvm > 0)
        this.$store.dispatch(FETCH_WINDOW_QUANTITY, this.$store.state.kvm);
    }
  },
  computed: {
    additionalParameterTitleText: {
      get() {
        if ('additional_parameter_title_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.additional_parameter_title_text;
        } else {
          return null;
        }
      }
    },
    fontColor: {
      get() {
          return this.$store.getters.primaryMediumColor;
      }
    },
    windowQuantity: {
      get () {
        return this.$store.getters.windowQuantity;
      }
    }
  },
  methods: {
    setShowInstruction() {
      this.showInstruction = !this.showInstruction;
    },
    setFourSides(value) {
      this.fourSides = value;
      this.$store.dispatch(SET_FOUR_SIDES, value);
    },
    setBars(value) {
      this.bars = value;
      this.$store.dispatch(SET_BARS, value);
    },
    setBalcony(value) {
      this.balcony = value;
      this.$store.dispatch(SET_BALCONY, value);
    },
    setFrames(value) {
      this.frames = value;
      this.$store.dispatch(SET_FRAMES, value);
    },
    setLadder(value) {
      this.ladder = value;
      this.$store.dispatch(SET_LADDER, value);
    },
    setExtraWindows(value) {
      this.extraWindows = value;
      this.$store.dispatch(SET_EXTRA_WINDOWS, value);
    },
    setIsExtraWindows(value) {
      this.isExtraWindows = value;
      // this.$store.dispatch(SET_EXTRA_WINDOWS, value);
    }
  }
}
</script>