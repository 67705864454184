
<template>
  <div>
    <div class="row-bokahem">

      <div class="col-bokahem-12">
        <div class="m-auto-bokahem mt-1 text-center-bokahem" v-bind:style="{ color: fontColor + ' !important'}">
          Beskriv ditt kontor
        </div>
      </div>

      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">
            Toaletter/badrum:
          </label>
          <div class="info-input-holder-bokahem" style="width:100px;">
            <input type="number"
                   v-model="bathroom"
                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
          </div>
        </div>
      </div>

      <div class="col-bokahem-12">
        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
          <label class="label-radio-width-bokahem">
            Kök/pentry:
          </label>
          <label class="common-checkbox-label-bokahem"
                 :class="{'common-radio-label_active': kitchen == 0 }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setKitchen(false)">
                <span class="common-custom-radio_after">
                </span>
                  </span>
            Nej
          </label>
          <label class="common-checkbox-label-bokahem common-radio-label"
                 :class="{'common-radio-label_active': kitchen == 1 }">
            <input type="radio" class="common-radio">
            <span class="common-custom-radio mr-1" @click="setKitchen(true)">
                  <span class="common-custom-radio_after"></span>
                  </span>
            Ja
          </label>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {
  SET_BATHROOM,
  SET_KITCHEN
} from "@/store/mutations.type";

export default {
  name: 'App',
  data() {
    return {
      kitchen: 0,
      bathroom: 1,
    }
  },
  computed: {
    fontColor: {
      get() {
          return this.$store.getters.primaryMediumColor;
      }
    },
  },
  watch: {
    'bathroom': function() {
      this.$store.dispatch(SET_BATHROOM, this.bathroom);
    },
  },
  methods: {
    setKitchen(value) {
      this.kitchen = value;
      this.$store.dispatch(SET_KITCHEN, value);
    },
    setBathroom(value) {
      this.bathroom = value;
      this.$store.dispatch(SET_BATHROOM, value);
    },
  }
}
</script>



