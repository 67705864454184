<template>
  <div class="col-bokahem-3 col-bokahem-sm-2 col-bokahem-md-2 col-bokahem-xl-2 input-wrap-col-bokahem">
    <div class="subtitle-input-bokahem" style="height: 14px; font-size:12px !important;padding-left:5px;">
      <span>{{ postalCodeSubtitle }}</span>
    </div>
    <div class="input-wrap header-bokahem-block">
      <svg class="icon-svg-bokahem">
        <LocationIcon></LocationIcon>
      </svg>
      <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" :class="{'bokahem-landing': !postalCodeValid && postalCode || (serviceClicked && !postalCode) || (searchButtonClicked && !postalCode)}" :placeholder="postalCodePlaceholder" type="text" v-model="postalCode" class="input-element-bokahem border-rounded-bokahem--left header-input">
    </div>
  </div>
</template>

<script>
import {
  SET_HEADER_ACTIVE,
  SET_POSTAL_CODE
} from "@/store/mutations.type";
import {
  FETCH_SUPPLIER_SERVICES
} from "@/store/actions.type";

import LocationIcon from '@/assets/custom-svg/icons/location-empty.svg';

export default {
  components: {
    LocationIcon
  },
  data() {
    return {
      serviceClicked: false,
      searchButtonClicked: false
    }
  },
  watch: {
    '$store.state.serviceClicked': function() {
      this.serviceClicked = this.$store.state.serviceClicked;
    },
    '$store.state.searchButtonClicked': function() {
      this.searchButtonClicked = true;
    }
  },
  computed: {
    backgroundPrimaryColor: {
      get () {
        return this.$store.getters.backgroundPrimaryColor;
      }
    },
    postalCodePlaceholder: {
      get() {
        if ('postal_code_placeholder' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.postal_code_placeholder;
        } else {
          return null;
        }
      }
    },
    postalCodeValid: {
      get () {
        return this.$store.getters.postalCodeValid
      }
    },
    postalCodeSubtitle: {
      get() {
        if ('postal_code_subtitle' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.postal_code_subtitle;
        } else {
          return null;
        }
      }
    },
    postalCode: {
      get () {
        return this.$store.state.postalCode;
      },
      set (value) {

        this.$store.dispatch(SET_HEADER_ACTIVE, true);

       // let string = value.replace(' ', '');

      //  if (string.length > 0) {
        //  this.postalCodeInputted = true;
     //   }

       // if (string.length == 5) {
            let params = {};
            params['postal_code'] = value;
            params['supplier_id'] = this.$store.getters.supplierId;

            this.$store.dispatch(FETCH_SUPPLIER_SERVICES, params);
            this.$store.dispatch(SET_POSTAL_CODE, value);

        //  this.$refs.serviceparameter.focus();
    //    }
      }
    }
  },
}
</script>