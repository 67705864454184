<template>
      <div v-bind:style="{ backgroundColor: checkBackground() + ' !important' }"
           class="pt-2 pb-2 mb-2">

        <div class="container-bokahem">
            <address class="address check_border_bokahem mb-1 pb-2 font-weight-medium-bokahem">
                <div class="row-bokahem">
                    <div class="col-bokahem-6 col-bokahem-md-8">
                        <div class="address-person">
                            <p>
                                <span>{{ checkoutData.order.order_first_name }} {{ checkoutData.order.order_last_name }}</span>
                            </p>
                            <p>
                                <span>{{ checkoutData.delivery.delivery_address }} </span>
                                <span>{{ checkoutData.delivery.delivery_postal_code }} </span> <span>{{ checkoutData.delivery.delivery_city }}</span>
                            </p>

                            <p v-if="checkoutData.show_invoice === true">
                                <span>{{ checkoutData.invoice.invoice_address }} </span>
                                <span>{{ checkoutData.invoice.invoice_postal_code }} </span> <span>{{ checkoutData.invoice.invoice_city }}</span>
                            </p>

                          <br>

                          <span class="d-inline-block">Tel: </span>

                          <span href="javascript:void(0)" class="d-inline-block">
                          {{ checkoutData.order.order_phone }}</span>
                          <br>
                          <span href="javascript:void(0)" class="d-inline-block">
                            {{ checkoutData.order.order_email }}</span> <br>
                          <span href="javascript:void(0)" class="d-inline-block">
                            {{ checkoutData.order.order_personal_number }}</span>
                          <br>
                          <span href="javascript:void(0)" class="d-inline-block font-weight-bold bokahem-main-pink" v-if="isCustomer === true">
                            Ni är en existerande kund.
                          </span>
                        </div>
                    </div>
                    <div class="col-bokahem-6 col-bokahem-md-4">
                        <div class="address-company">
                            <p>
                                <span>{{ widgetFields.order_key_handling.name}}: {{ checkoutData.order.order_key_handling }}</span>
                                <span v-if="supplierSms === true && checkoutData.order.order_sms === true">SMS-påminnelse: JA</span>
                                <span v-if="supplierSms === true && checkoutData.order.order_sms === false">SMS-påminnelse: NEJ</span>
                            </p>
                        </div>
                    </div>
                </div>

            </address>
            <div class="p-1 text-black-bokahem">
                <div class="common-prise-block">
                    <h3 class="heading-bokahem mb-2">{{ gap.service_name }}</h3>
                </div>
                <dl class="price-dl-list-bokahem font-weight-medium-bokahem mb-0">
                    <div class="mb-2">
                        <span class="d-block">
                            <b>{{ gap.date_description.day_of_week_long }} kl {{ gap.start_time}}
                               <span v-if="gap.show_service_time === 1">
                                     ({{ gap.service_time / 60 }} tim X {{ gap.employee_quantity }} pers.)
                                </span>
                                <span v-if="gap.show_service_time === 0">
                                  ({{ gap.employee_quantity }} pers.)
                                </span>
                            </b>
                        </span>
                    </div>
                    <div class="mb-2">
                        <span class="d-block">
                            <b>Start {{ gap.date_description.day_number }} {{ gap.date_description.month }}</b>
                        </span>
                    </div>
                    <div class="price-dl-list-bokahem-item">
                        <dt>{{ gap.service_name_raw }}:</dt>
                        <dd v-if="serviceGroupId == 1">{{ gap.widget_prices.service | toCurrency }}</dd>
                        <dd v-if="serviceGroupId == 2">{{ gap.widget_prices_company.service | toCurrency }}</dd>
                    </div>
                    <div class="price-dl-list-bokahem-item" v-if="gap.service_startup === true && gap.prices.startup_with_vat > 0">
                        <dt>Inställelseavgift:</dt>
                        <dd v-if="serviceGroupId == 1">{{ gap.widget_prices.startup | toCurrency }}</dd>
                        <dd v-if="serviceGroupId == 2">{{ gap.widget_prices_company.startup | toCurrency }}</dd>
                    </div>

                    <div class="price-dl-list-bokahem-item"  v-if="serviceGroupId == 2 && gap.discounts.total.value_raw > 0">
                      <dt>Rabatt ({{ gap.discount_description_string}}):</dt>
                      <dd>-{{ gap.widget_prices_company.rabbat | toCurrency }}</dd>
                    </div>

                    <div class="price-dl-list-bokahem-item" v-if="serviceGroupId == 1 && gap.discounts.total.value_raw > 0">
                        <dt>Rabatt ({{ gap.discount_description_string}}):</dt>
                        <dd>-{{ gap.widget_prices.rabbat | toCurrency }}</dd>
                    </div>

                  <div class="price-dl-list-bokahem-item mt-2" v-if="serviceGroupId == 1 && checkoutData.order.order_rut == 'Ja'">
                    <dt>Rutavdrag:</dt>
                    <dd>-{{ gap.widget_prices.rutavdrag | toCurrency }}</dd>
                  </div>

                    <div class="price-dl-list-bokahem-item mt-2" v-if="serviceGroupId == 1">

                      <dt v-if="gap.is_recurrent === true" class="font-weight-bold-bokahem">Pris per tillfälle:</dt>
                      <dt v-if="gap.is_recurrent === false" class="font-weight-bold-bokahem">Pris:</dt>

                      <dd class="font-weight-bold-bokahem" v-if="checkoutData.order.order_rut == 'Ja'">
                        {{ gap.widget_prices.pris | toCurrency }}
                      </dd>

                      <dd class="font-weight-bold-bokahem" v-if="checkoutData.order.order_rut == 'Nej'">
                        {{ gap.widget_prices_no_rut.pris | toCurrency }}
                      </dd>

                    </div>

                  <div class="price-dl-list-bokahem-item mt-2" v-if="serviceGroupId == 2">

                    <dt v-if="gap.is_recurrent === true" class="font-weight-bold-bokahem">Pris per tillfälle ex moms:</dt>
                    <dt v-if="gap.is_recurrent === false" class="font-weight-bold-bokahem">Pris ex moms:</dt>

                    <dd class="font-weight-bold-bokahem">
                      {{ gap.widget_prices_company.pris_with_discount | toCurrency }}
                    </dd>

                  </div>

                  <div class="price-dl-list-bokahem-item"
                       v-if="serviceGroupId == 2 && gap.widget_prices_company.extra_description && gap.widget_prices_company.extra_part > 0">
                    <dt>{{ gap.widget_prices_company.extra_description }}:</dt>
                    <dd>{{ gap.widget_prices_company.extra_part | toCurrency }}</dd>
                  </div>

                  <div class="price-dl-list-bokahem-item mt-2" v-if="serviceGroupId == 2">
                    <dt>Moms, 25%:</dt>
                    <dd>
                      {{ gap.widget_prices_company.moms | toCurrency }}
                    </dd>
                  </div>

                    <div class="price-dl-list-bokahem-item mt-2" v-if="gap.new_prices.discount_percent > 0
                    && isCustomer === false">
                      <dt>rabattkoden <span class="bokahem-gray-text">{{ campaignCode }}</span>, {{ gap.new_prices.discount_percent }}%:</dt>

                      
                      <dd v-if="serviceGroupId == 1 && checkoutData.order.order_rut == 'Ja'">-{{ gap.widget_prices.nykundrabbat | toCurrency }}</dd>
                      <dd v-if="serviceGroupId == 1 && checkoutData.order.order_rut == 'Nej'">-{{ gap.widget_prices_no_rut.nykundrabbat | toCurrency }}</dd>

                      <dd v-if="serviceGroupId == 2">-{{ gap.widget_prices_company.nykundsrabbat | toCurrency }}</dd>
                    </div>

                    <div class="price-dl-list-bokahem-item" v-if="gap.new_prices.discount_percent > 0 && isCustomer === false">
                      
                      <dt class="font-weight-bold-bokahem" v-if="serviceGroupId == 1">Pris med rabatt:</dt>
                      <dt class="font-weight-bold-bokahem" v-if="serviceGroupId == 2">Pris med rabatt ex moms:</dt>
                      
                      <dd class="font-weight-bold-bokahem" v-if="serviceGroupId == 1 && checkoutData.order.order_rut == 'Ja'">
                        {{ gap.widget_prices.pris_nykundrabbat | toCurrency }}
                      </dd>
                      <dd class="font-weight-bold-bokahem" v-if="serviceGroupId == 1 && checkoutData.order.order_rut == 'Nej'">
                        {{ gap.widget_prices_no_rut.pris_nykundrabbat | toCurrency }}
                      </dd>
                      <dd class="font-weight-bold-bokahem" v-if="serviceGroupId == 2">
                        {{ gap.widget_prices_company.pris_nykundsrabbat | toCurrency }}
                      </dd>
                    </div>

                    <div class="price-dl-list-bokahem-item" v-if="serviceGroupId == 2 && gap.new_prices.discount_percent > 0">
                      <dt>Moms, 25%:</dt>
                      <dd v-if="serviceGroupId == 2">{{ gap.widget_prices_company.moms_nykundsrabbat | toCurrency }}</dd>
                    </div>
                </dl>

            </div>

            <div class="block-text pl-1 font-weight-medium-bokahem text-black-bokahem">
                <p style="max-width:550px;" v-if="campaignCodeTerms && gap.new_prices.discount_percent > 0">
                  {{ campaignCodeTerms }}
                </p>

                <p style="max-width:550px;">
                  Detta är en standardtjänst. Pristillägg kan förekomma om angiven information
                  inte är korrekt eller om omständigheterna avviker.
                </p>

                <p>Bostad: {{ checkoutData.order.order_type_appartment }}.{{ squareMeters }}{{ checkoutData.order.order_additional_appartment_info }}</p>
                <p v-if="checkoutData.order.order_mission_description">Uppdrag: {{ checkoutData.order.order_mission_description }}</p>

              <div class="mb-2 bokahem-p-row-text" v-if="customerResponsible
                && customerResponsible.name
                && customerResponsible.value">
                <b>{{ customerResponsible.name }}: </b>
                <span v-html="customerResponsible.value"></span>
              </div>

              <div class="mb-2 bokahem-p-row-text" v-if="supplierResponsible
                && supplierResponsible.name
                && supplierResponsible.value">
                <b>{{ supplierResponsible.name }}: </b>
                <span v-html="supplierResponsible.value">
                </span>
              </div>
            </div>

            <div class="block-text pl-1 font-weight-medium-bokahem text-black-bokahem"
                 v-if="paymentInformation || cancelInformation">
                <div v-if="paymentInformation !== null" v-html="paymentInformation"></div>
                <div v-if="cancelInformation !== null" v-html="cancelInformation"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            checkBackground() {
                if (this.$store.getters.finished !== null
                    && this.$store.getters.pluginSettings.plugin_step_to_return == 2) {
                  return this.$store.getters.backgroundLightColor;
                } else {
                  return this.$store.getters.backgroundSecondaryColor;
                }
            }
        },
        computed: {
            cancelInformation() {
              if ('cancel_information' in this.$store.getters.supplier) {
                return this.$store.getters.supplier.cancel_information;
              }
              return null;
            },
            paymentInformation() {
              if ('payment_information' in this.$store.getters.supplier) {
                if ('value' in this.$store.getters.supplier.payment_information
                    && this.$store.getters.supplier.payment_information.value != null && this.$store.getters.supplier.payment_information.value != 'null') {
                  return this.$store.getters.supplier.payment_information.value;
                }
              }
              return null;
            },
            customerResponsible() {
              if ('responsible_fields' in this.$store.getters.supplier) {
                if ('customer' in this.$store.getters.supplier.responsible_fields) {
                  return this.$store.getters.supplier.responsible_fields.customer;
                }
              }
              return false;
            },
            supplierResponsible() {
              if ('responsible_fields' in this.$store.getters.supplier) {
                if ('supplier' in this.$store.getters.supplier.responsible_fields) {
                  return this.$store.getters.supplier.responsible_fields.supplier;
                }
              }
              return false;
            },
            campaignCode() {
              return this.$store.getters.campaignCode;
            },
            campaignCodeTerms() {
              return this.$store.getters.campaignCodeTerms;
            },
            supplierSms() {
              return this.$store.getters.pluginSettings.responsible_fields.supplier_sms;
            },
            squareMeters() {
                if (this.$store.getters.kvm) {
                    return ' ' + this.$store.getters.kvm + ' kvm. '
                } else {
                    return '';
                }
            },
            checkoutData() {
                return this.$store.getters.checkoutData
            },
            serviceGroupId() {
              return this.$store.getters.serviceGroupId;
            },
            supplierId() {
              return this.$store.getters.supplierId;
            },
            finished() {
                return this.$store.getters.finished
            },
            discountTerms() {
              return this.$store.getters.newCustomerDiscountTerms;
            },
            isCustomer() {
              return this.$store.getters.isNewCustomer;
            },
            gap() {
                return this.$store.getters.selectedGap;
            },
            widgetFields() {
                return this.$store.getters.widgetFields;
            }
        }
    }
</script>
