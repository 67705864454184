import Vue from "vue";
import Vuex from "vuex";

import {
    PluginSettingsService,
    CheckPostalCodeService,
    SupplierServicesService,
    SendInterestFormService,
    WindowQuantityService,
    GapsService,
    WidgetFieldsService,
    SuppliersService,
    CreateBookingService,
    CampaignCodeService,
    AreasService,
    FetchPersonalNumberService,
    CheckPersonalNumberService
} from "@/common/api.service";

import {
    FETCH_PLUGIN_SETTINGS,
    FETCH_SUPPLIER_SERVICES,
    CHECK_POSTAL_CODE,
    SELECT_SERVICE,
    SELECT_RECURRENCE_OPTION,
    SEND_INTEREST_FORM,
    FETCH_WINDOW_QUANTITY,
    FETCH_GAPS,
    SELECT_GAP,
    FETCH_WIDGET_FIELDS,
    IS_NEW_CUSTOMER,
    SET_CHECKOUT_DATA,
    UNAPPROVE_CHECKOUT,
    APPROVE_CHECKOUT,
    FINISH_CHECKOUT,
    FETCH_PERSONAL_NUMBER,
    CHECK_PERSONAL_NUMBER,
    FETCH_SUPPLIERS,
    FETCH_PLACE,
    APPLY_CAMPAIGN_CODE
} from "./actions.type";
import {
    SET_PLUGIN_SETTINGS,
    SET_POSTAL_CODE_VALID,
    SET_SUPPLIER_SERVICES,
    SET_RECURRENCE_OPTIONS,
    SET_IS_LOADING,
    SET_POSTAL_CODE,
    SET_KVM,
    SET_EXTRA_WINDOWS,
    SET_BARS,
    SET_BALCONY,
    SET_LADDER,
    SET_FRAMES,
    SET_FOUR_SIDES,
    SET_EXCLUDE_TAG,
    INTEREST_FORM_IS_SENT,
    SET_SEARCH_BUTTON_CLICKED,
    SET_SERVICE_CLICKED,
    SET_WINDOW_QUANTITY,
    SET_SERVICE_LIST,
    SET_GAPS,
    SET_EFFORT,
    SET_NO_EFFORT,
    SET_WIDGET_FIELDS,
    SET_IS_NEW_CUSTOMER,
    SET_UP_EVENING_DAY,
    SET_STARTING_DATE,
    SET_NO_GAPS,
    SET_PERSONAL_NUMBER_DATA,
    SET_HEADER_ACTIVE,
    SET_PERSONAL_NUMBER_CHECK,
    SET_SUPPLIER,
    SET_PLACE,
    SET_BATHROOM,
    SET_KITCHEN,
    CLEAR_ALL_EMPLOYEE_FILTER,
    SELECT_ALL_EMPLOYEE_FILTER,
    SET_CAMPAIGN_CODE,
    UPDATE_GAP_COMPANY_PRICES,
    UPDATE_GAP_PRIVATE_PRICES,
    UPDATE_GAP_NEW_PRICES,
    SET_CAMPAIGN_INITIAL,
    UPDATE_GAP_WIDGET_PRICES_NO_RUT
} from "./mutations.type";

import {
    PRIMARY_COLOR, PRIMARY_MEDIUM_COLOR, VALIDATION_COLOR, BACKGROUND_PRIMARY_COLOR, TEXT_COLOR,
    BACKGROUND_SECONDARY_COLOR,
    BACKGROUND_COLOR,
    BACKGROUND_LIGHT_COLOR, HIGHLIGHT_COLOR
} from "./colors.type";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoading: false,
        postalCode: null,
        kitchen: 0,
        campaignCode: [],
        campaignInitial: 0,
        bathroom: 1,
        personalNumberData: [],
        finished: null,
        personalNumberCheck: null,
        headerActive: true,
        selectedServiceName: null,
        selectedServiceDescriptionLong: null,
        selectedServiceDescriptionShort: null,
        pluginSettings: [],
        recurrenceOptions: [],
        postalCodeValid: null,
        supplierServices: [],
        selectedService: null,
        newCustomerDiscount: null,
        selectedRecurrenceOption: null,
        selectedRecurrenceOptionId: null,
        selectedServiceId: null,
        selectedServiceCategoryId: null,
        additionalParameter: false,
        supplierId: null,
        kvm: null,
        place: null,
        excludeTag: 0,
        serviceGroupId: 1,
        interestFormIsSent: false,
        searchButtonClicked: false,
        serviceClicked: false,
        bars: false,
        ladder: false,
        supplier: null,
        frames: false,
        fourSides: false,
        balcony: false,
        extraWindows: 0,
        windowQuantity: 0,
        serviceList: [],
        gaps: [],
        fetchGapEffort: 0,
        noEffort: false,
        selectedGap: null,
        widgetFields: null,
        checkoutData: null,
        isNewCustomer: false,
        approvedCheckout: false,
        startingDate: new Date(),
        eveningDay: 1,
        newCustomerDiscountTerms: null,
        gapEmployees: [],
        selectedEmployees: [],
        employees: [],
        selectedGapEmployeeId: null,
    },
    mutations: {
        [SET_CAMPAIGN_CODE](state, campaignCode) {
            state.campaignCode = campaignCode;
        },
        [UPDATE_GAP_WIDGET_PRICES_NO_RUT](state, widgetPricesNoRut) {
            if (state.selectedGap !== null) {
                if ('widget_prices_no_rut' in state.selectedGap) {
                    state.selectedGap.widget_prices_no_rut = widgetPricesNoRut;
                }
            }
        },
        [UPDATE_GAP_NEW_PRICES](state, newPrices) {
            if (state.selectedGap !== null) {
                if ('new_prices' in state.selectedGap) {
                    state.selectedGap.new_prices = newPrices;
                }
            }
        },
        [UPDATE_GAP_COMPANY_PRICES](state, companyPrices) {
            if (state.selectedGap !== null) {
                if ('widget_prices_company' in state.selectedGap) {
                    state.selectedGap.widget_prices_company = companyPrices;
                }
            }
            
        },
        [UPDATE_GAP_PRIVATE_PRICES](state, privatePrices) {
            if (state.selectedGap !== null) {
                if ('widget_prices' in state.selectedGap) {
                    state.selectedGap.widget_prices = privatePrices;
                }
            }
        },
        [SET_PERSONAL_NUMBER_CHECK](state, personalNumberCheck) {
            state.personalNumberCheck = personalNumberCheck;
        },
        [SET_HEADER_ACTIVE](state, headerActive) {
            state.headerActive = headerActive;
        },
        [SET_NO_GAPS](state, noGaps) {
            state.noGaps = noGaps;
        },
        [SET_CAMPAIGN_INITIAL](state) {
            state.campaignInitial++;
        },
        [SET_UP_EVENING_DAY](state, eveningDay) {
            state.eveningDay = eveningDay;
        },
        [SET_PLACE](state, place) {
            state.place = place;
        },
        [SET_STARTING_DATE](state, startingDate) {
            state.startingDate = startingDate;
        },
        [UNAPPROVE_CHECKOUT](state) {
            state.approvedCheckout = false;
        },
        [SET_WIDGET_FIELDS](state, widgetFields) {
            state.widgetFields = widgetFields;
        },
        [SET_IS_NEW_CUSTOMER](state, isNewCustomer) {
            state.isNewCustomer = isNewCustomer.data;
        },
        [SELECT_RECURRENCE_OPTION](state, selectedRecurrenceOption) {
            if (selectedRecurrenceOption in state.recurrenceOptions) {
                state.selectedRecurrenceOption = state.recurrenceOptions[selectedRecurrenceOption].prefix;
                state.selectedRecurrenceOptionId = state.recurrenceOptions[selectedRecurrenceOption].id;
                state.selectedServiceDescriptionLong = state.recurrenceOptions[selectedRecurrenceOption].service_description.long;
                state.selectedServiceDescriptionShort = state.recurrenceOptions[selectedRecurrenceOption].service_description.short;
                state.newCustomerDiscountTerms = state.recurrenceOptions[selectedRecurrenceOption].new_customer_discount_terms;
                state.newCustomerDiscount = state.recurrenceOptions[selectedRecurrenceOption].new_customer_discount;
                state.selectedServiceId = state.recurrenceOptions[selectedRecurrenceOption].service_id;
            } else {
                state.selectedRecurrenceOption = null;
                state.selectedRecurrenceOptionId = null;
            }
        },
        [SELECT_SERVICE](state, selectedService) {
            state.selectedRecurrenceOption = null;
            state.newCustomerDiscount = null;

            state.selectedServiceName = null;
            state.selectedServiceDescription = null;

            if (selectedService in state.supplierServices) {

                if ('recurrence_options' in state.supplierServices[selectedService]) {
                    state.recurrenceOptions = state.supplierServices[selectedService].recurrence_options;
                    
                    state.selectedRecurrenceOption = state.supplierServices[selectedService].recurrence_options[0];
                    state.selectedServiceId = state.supplierServices[selectedService].recurrence_options[0]['service_id'];
                    state.selectedServiceDescriptionLong = state.supplierServices[selectedService].recurrence_options[0]['service_description'].long;
                    state.selectedServiceDescriptionShort = state.supplierServices[selectedService].recurrence_options[0]['service_description'].short;
                    state.newCustomerDiscount = state.supplierServices[selectedService].recurrence_options[0]['new_customer_discount'];
                    state.newCustomerDiscountTerms = state.supplierServices[selectedService].recurrence_options[0]['new_customer_discount_terms'];
                }

                state.selectedService = state.supplierServices[selectedService].prefix;

                if ('service_category_id' in state.supplierServices[selectedService]) {
                    state.selectedServiceCategoryId = state.supplierServices[selectedService].service_category_id;
                }

                if ('name' in state.supplierServices[selectedService]) {
                    state.selectedServiceName = state.supplierServices[selectedService].name;
                }

                if ('description' in state.supplierServices[selectedService]) {
                    state.selectedServiceDescription = state.supplierServices[selectedService].description;
                }

                if ('service_group_id' in state.supplierServices[selectedService]) {
                    state.serviceGroupId = state.supplierServices[selectedService].service_group_id;
                }

                if ('additional_parameter' in state.supplierServices[selectedService]) {
                    state.additionalParameter = state.supplierServices[selectedService].additional_parameter;
                }

                /*
                if ('new_customer_discount' in state.supplierServices[selectedService]) {
                    state.newCustomerDiscount = state.supplierServices[selectedService].new_customer_discount;
                }

                if ('new_customer_discount_terms' in state.supplierServices[selectedService]) {
                    state.newCustomerDiscountTerms = state.supplierServices[selectedService].new_customer_discount_terms;
                }
                 */
            }
        },
        [SET_WINDOW_QUANTITY](state, windowQuantity) {
            state.windowQuantity = windowQuantity;
        },
        [SET_PLUGIN_SETTINGS](state, pluginSettings) {
            state.pluginSettings = pluginSettings;
            if ('supplier_id' in pluginSettings) {
                state.supplierId = pluginSettings.supplier_id;
            }
            /*
            if ('kvm' in this.$root) {
                state.kvm = this.$root.kvm;
            } else if ('default_kvm' in pluginSettings) {
                state.kvm = pluginSettings.default_kvm;
            }

             */
        },
        [SET_RECURRENCE_OPTIONS](state, recurrenceOptions) {
            state.recurrenceOptions = recurrenceOptions;
            state.newCustomerDiscount = null;
        },
        [SET_CHECKOUT_DATA](state, checkoutData) {
            state.checkoutData = checkoutData;
            state.approvedCheckout = true;
        },
        [SET_SUPPLIER_SERVICES](state, supplierServices) {
            state.supplierServices = supplierServices;
            state.newCustomerDiscount = null;
        },
        [SET_POSTAL_CODE_VALID](state, postalCodeValid) {
            state.postalCodeValid = postalCodeValid;
        },
        [SET_POSTAL_CODE](state, postalCode) {
            state.postalCode = postalCode;
        },
        [SET_KVM](state, kvm) {
            state.kvm = kvm;
        },
        [SET_IS_LOADING](state, isLoading) {
            state.isLoading = isLoading;
        },
        [INTEREST_FORM_IS_SENT](state, interestFormIsSent) {
            state.interestFormIsSent = interestFormIsSent;
        },
        [SET_SEARCH_BUTTON_CLICKED](state) {
            state.searchButtonClicked = true;
        },
        [SET_SERVICE_CLICKED](state, serviceClicked) {
            state.serviceClicked = serviceClicked;
        },
        [SET_FRAMES](state, frames) {
            state.frames = frames;
        },
        [SET_FOUR_SIDES](state, fourSides) {
            state.fourSides = fourSides;
        },
        [SET_BARS](state, bars) {
            state.bars = bars;
        },
        [SET_EXTRA_WINDOWS](state, extraWindows) {
            state.extraWindows = extraWindows;
        },
        [SET_BALCONY](state, balcony) {
            state.balcony = balcony;
        },
        [SET_LADDER](state, ladder) {
            state.ladder = ladder;
        },
        [SET_KITCHEN](state, kitchen) {
            state.kitchen = kitchen;
        },
        [SET_BATHROOM](state, bathroom) {
            state.bathroom = bathroom;
        },
        [SET_LADDER](state, ladder) {
            state.ladder = ladder;
        },
        [SET_SERVICE_LIST](state, serviceList) {
            state.serviceList = serviceList;
        },
        [SET_PERSONAL_NUMBER_DATA](state, personalNumberData) {
            state.personalNumberData = personalNumberData;
        },
        [SET_GAPS](state, gaps) {
            state.gaps = gaps;
            state.selectedGap = null;
            state.checkoutData = null;
            state.fetchGapEffort++;
        },
        [SET_NO_EFFORT](state, noEffort) {
            state.noEffort = noEffort;
        },
        [SET_EFFORT](state, effort) {
            state.fetchGapEffort = effort;
        },
        [SELECT_GAP](state, params) {
            state.selectedGap = state.gaps.gaps[params.startDate][params.startTime + '-' + params.endTime][params.employeeId];
            state.fetchGapEffort = 0;
        },
        [APPROVE_CHECKOUT](state) {
            state.approvedCheckout = true;
        },
        [FINISH_CHECKOUT](state, data) {
            state.finished = data;
        },
        [SET_SUPPLIER](state, supplierData) {
            state.supplier = supplierData.data;
        },
    },
    actions: {
        [APPLY_CAMPAIGN_CODE]({ commit }, params) {
            return CampaignCodeService.get(params)
                .then(({ data }) => {

                    commit(SET_CAMPAIGN_CODE, data.data);

                    if ('pr' in data.data) {
                        commit(UPDATE_GAP_PRIVATE_PRICES, data.data.pr);
                    }
                    
                    if ('cm' in data.data) {
                        commit(UPDATE_GAP_COMPANY_PRICES, data.data.cm);
                    }

                    if ('np' in data.data) {
                        commit(UPDATE_GAP_NEW_PRICES, data.data.np);
                    }

                    if ('wnr' in data.data) {
                        commit(UPDATE_GAP_WIDGET_PRICES_NO_RUT, data.data.wnr);
                    }
                })
                .catch(error => {
                    commit(SET_CAMPAIGN_CODE, []);
                    return error;
                });
        },
        [FETCH_PLACE]({ commit }, params) {
            return AreasService.get(params)
                .then(({ data }) => {
                    commit(SET_PLACE, data.place);
                })
                .catch(error => {
                    commit(SET_PLACE, null);
                    return error;
                });
        },
        [FINISH_CHECKOUT]({commit}, params) {
            return CreateBookingService.get(params)
                .then(({ data }) => {
                    commit(FINISH_CHECKOUT, data);
                })
                .catch(error => {
                    commit(FINISH_CHECKOUT, null);
                    return error;
                });
        },
        [FETCH_WIDGET_FIELDS]({ commit }) {
            return WidgetFieldsService.get()
                .then(({ data }) => {
                    commit(SET_WIDGET_FIELDS, data);
                })
                .catch(error => {
                    commit(SET_WIDGET_FIELDS, null);
                    return error;
                    // throw new Error(error);
                });
        },
        [IS_NEW_CUSTOMER]({ commit }, params) {
            let data = {data: false, param: params};
            commit(SET_IS_NEW_CUSTOMER, data);
            /*
            return IsNewCustomerService.get(params)
                .then(({ data }) => {
                    commit(SET_IS_NEW_CUSTOMER, data);
                })
                .catch(error => {
                    return error;
                });

             */
        },
        [SELECT_GAP]({ commit}, startDate, startTime, endTime, employeeId) {
            commit(SET_CAMPAIGN_CODE, []);
            commit(SET_CAMPAIGN_INITIAL);
            commit(SELECT_GAP, startDate, startTime, endTime, employeeId);
        },
        [SET_CHECKOUT_DATA]({ commit}, checkoutData) {
            commit(SET_CHECKOUT_DATA, checkoutData);
        },
        [FETCH_GAPS]({ commit }, params) {
            commit(SET_IS_LOADING, true);
            if ('set_effort' in params) {
                commit(SET_NO_EFFORT, true);
            }

            if (params['cleanEffort'] == true) {
                commit(SET_EFFORT, 0);
                if (typeof params['start_date'] != "undefined" && typeof params['end_date'] != "undefined") {
                    commit(SET_EFFORT, 1);
                }
            }

            return GapsService.get(params)
                .then(({ data }) => {
                    commit(SET_GAPS, data);
                    commit(SET_IS_LOADING, false);
                    commit(SET_CAMPAIGN_CODE, []);
                    commit(SET_CAMPAIGN_INITIAL);
                }).catch(error => {
                    commit(SET_GAPS, []);
                    commit(SET_IS_LOADING, false);
                    commit(SET_CAMPAIGN_CODE, []);
                    commit(SET_CAMPAIGN_INITIAL);
                    return error;
                });
        },
        [FETCH_WINDOW_QUANTITY]({ commit }, params) {
            return WindowQuantityService.get(params)
                .then(({ data }) => {
                    commit(SET_WINDOW_QUANTITY, data.data);
                })
                .catch(error => {
                    commit(SET_WINDOW_QUANTITY, 0);
                    return error;
                });
        },
        [FETCH_SUPPLIERS]({ commit }, params) {
            commit(SET_IS_LOADING, true);
            return SuppliersService.get(params)
                .then(({ data }) => {
                    commit(SET_SUPPLIER, data);
                })
                .catch(error => {
                    return error;
                });
        },
        [FETCH_SUPPLIER_SERVICES]({ commit }, params) {
            return SupplierServicesService.get(params)
                .then(({ data }) => {
                    commit(SET_POSTAL_CODE_VALID, true);
                    commit(SET_SUPPLIER_SERVICES, data.services);
                    commit(SET_SERVICE_LIST, data.service_list);
                })
                .catch(error => {
                    commit(SET_POSTAL_CODE_VALID, false);
                    commit(SET_SUPPLIER_SERVICES, []);
                    commit(SET_RECURRENCE_OPTIONS, []);
                    commit(SET_SERVICE_LIST, []);
                    return error;
                    // throw new Error(error);
                });
        },
        [FETCH_PLUGIN_SETTINGS]({ commit }) {
            return PluginSettingsService.get()
                .then(({ data }) => {
                    commit(SET_PLUGIN_SETTINGS, data);
                })
                .catch(error => {
                    commit(SET_PLUGIN_SETTINGS, []);
                    return error;
                    // throw new Error(error);
                });
        },
        [FETCH_PERSONAL_NUMBER]({ commit }, params) {

            return FetchPersonalNumberService.get(params)
                .then(({ data }) => {
                    commit(SET_PERSONAL_NUMBER_DATA, data.data);
                    commit(SET_IS_LOADING, false);
                })
                .catch(error => {
                    let data = [];
                    commit(SET_PERSONAL_NUMBER_DATA, data);
                    return error;
                    // throw new Error(error);
                });
        },
        [CHECK_PERSONAL_NUMBER]({ commit }, params) {
            return CheckPersonalNumberService.get(params)
                .then(({ data }) => {
                    commit(SET_PERSONAL_NUMBER_CHECK, data.data);
                })
                .catch(error => {
                    commit(SET_PERSONAL_NUMBER_CHECK, null);
                    return error;
                });
        },
        [CHECK_POSTAL_CODE]({ commit }, params) {
            return CheckPostalCodeService.get(params)
                .then(({ data }) => {
                    commit(SET_POSTAL_CODE_VALID, data);
                })
                .catch(error => {
                    commit(SET_POSTAL_CODE_VALID, false);
                    return error;
                });
        },
        [SEND_INTEREST_FORM]({ commit }, params) {
            return SendInterestFormService.get(params)
                .then(() => {
                    commit(INTEREST_FORM_IS_SENT, true);
                })
                .catch(error => {
                    commit(INTEREST_FORM_IS_SENT, false);
                    return error;
                });
        },
        [SET_POSTAL_CODE]({ commit}, postalCode) {
            commit(SET_POSTAL_CODE, postalCode);
        },
        [SET_KVM]({ commit}, kvm) {
            commit(SET_KVM, kvm);
        },
        [INTEREST_FORM_IS_SENT]({ commit}, interestFormIsSent) {
            commit(INTEREST_FORM_IS_SENT, interestFormIsSent);
        },
        [SET_SEARCH_BUTTON_CLICKED]({ commit}) {
            commit(SET_SEARCH_BUTTON_CLICKED);
        },
        [SET_SERVICE_CLICKED]({ commit}, serviceClicked) {
            commit(SET_SERVICE_CLICKED, serviceClicked);
        },
        [SET_EXCLUDE_TAG]({ commit}, excludeTag) {
            commit(SET_EXCLUDE_TAG, excludeTag);
        },
        [SELECT_SERVICE]({ commit}, selectedService) {
            commit(SELECT_SERVICE, selectedService);
        },
        [SELECT_RECURRENCE_OPTION]({ commit}, selectedRecurrenceOption) {
            commit(SELECT_RECURRENCE_OPTION, selectedRecurrenceOption);
        },
        [SET_FRAMES]({ commit}, frames) {
            commit(SET_FRAMES, frames);
        },
        [SET_FOUR_SIDES]({ commit}, fourSides) {
            commit(SET_FOUR_SIDES, fourSides);
        },
        [SET_BARS]({ commit}, bars) {
            commit(SET_BARS, bars);
        },
        [SET_EXTRA_WINDOWS]({ commit}, extraWindows) {
            commit(SET_EXTRA_WINDOWS, extraWindows);
        },
        [SET_BALCONY]({ commit}, balcony) {
            commit(SET_BALCONY, balcony);
        },
        [SET_LADDER]({ commit}, ladder) {
            commit(SET_LADDER, ladder);
        },
        [SET_KITCHEN]({ commit}, kitchen) {
            commit(SET_KITCHEN, kitchen);
        },
        [SET_BATHROOM]({ commit}, bathroom) {
            commit(SET_BATHROOM, bathroom);
        },
        [CLEAR_ALL_EMPLOYEE_FILTER]({ commit}) {
            commit(CLEAR_ALL_EMPLOYEE_FILTER);
        },
        [SELECT_ALL_EMPLOYEE_FILTER]({ commit}) {
            commit(SELECT_ALL_EMPLOYEE_FILTER);
        },
        [UNAPPROVE_CHECKOUT]({ commit}) {
            commit(UNAPPROVE_CHECKOUT);
        },
        [APPROVE_CHECKOUT]({ commit}) {
            commit(APPROVE_CHECKOUT);
        },
        [SET_UP_EVENING_DAY]({ commit }, eveningDay) {
            commit(SET_UP_EVENING_DAY, eveningDay);
        },
        [SET_IS_LOADING]({ commit }, isLoading) {
            commit(SET_IS_LOADING, isLoading);
        },
        [SET_STARTING_DATE]({ commit }, startingDate) {
            commit(SET_STARTING_DATE, startingDate);
        },
        [SET_HEADER_ACTIVE]({ commit }, headerActive) {
            commit(SET_HEADER_ACTIVE, headerActive);
        }
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        headerActive(state) {
            return state.headerActive;
        },
        personalNumberData(state) {
            return state.personalNumberData;
        },
        postalCode(state) {
            return state.postalCode;
        },
        kvm(state) {
            return state.kvm;
        },
        balcony(state) {
            return state.balcony;
        },
        ladder(state) {
            return state.ladder;
        },
        bars(state) {
            return state.bars;
        },
        kitchen(state) {
            return state.kitchen;
        },
        bathroom(state) {
            return state.bathroom;
        },
        fourSides(state) {
            return state.fourSides;
        },
        frames(state) {
            return state.frames;
        },
        extraWindows(state) {
            return state.extraWindows;
        },
        excludeTag(state) {
            return state.excludeTag;
        },
        pluginSettings(state) {
            return state.pluginSettings;
        },
        recurrenceOptions(state) {
            return state.recurrenceOptions;
        },
        supplierServices(state) {
            return state.supplierServices;
        },
        postalCodeValid(state) {
            return state.postalCodeValid;
        },
        supplierId(state) {
            return state.supplierId;
        },
        additionalParameter(state) {
            return state.additionalParameter;
        },
        selectedService(state) {
            return state.selectedService;
        },
        selectedServiceId(state) {
            return state.selectedServiceId;
        },
        serviceCategoryId(state) {
            return state.selectedServiceCategoryId;
        },
        serviceGroupId(state) {
            return state.serviceGroupId;
        },
        newCustomerDiscount(state) {
            return state.newCustomerDiscount;
        },
        newCustomerDiscountTerms(state) {
            return 'Villkor för nykundsrabatt: ' + state.newCustomerDiscountTerms;
        },
        selectedRecurrenceOption(state) {
            return state.selectedRecurrenceOption;
        },
        selectedRecurrenceOptionId(state) {
            return state.selectedRecurrenceOptionId;
        },
        interestFormIsSent(state) {
            return state.interestFormIsSent;
        },
        searchButtonClicked(state) {
            return state.searchButtonClicked;
        },
        serviceClicked(state) {
            return state.serviceClicked;
        },
        windowQuantity(state) {
            return state.windowQuantity;
        },
        serviceList(state) {
            return state.serviceList;
        },
        gaps(state) {
            return state.gaps;
        },
        selectedGap(state) {
            return state.selectedGap;
        },
        gapStartDate(state) {
            if (state.selectedGap !== null 
                && 'start_date' in state.selectedGap) {
                    return state.selectedGap.start_date;
            }
            return null;
        },
        gapStartTime(state) {
            if (state.selectedGap !== null 
                && 'start_time' in state.selectedGap) {
                    return state.selectedGap.start_time;
            }
            return null;
        },
        gapEndTime(state) {
            if (state.selectedGap !== null 
                && 'end_time' in state.selectedGap) {
                    return state.selectedGap.end_time;
            }
            return null;
        },
        selectedGapKey(state) {
            if (state.selectedGap !== null) {
                return state.selectedGap.start_date + '' + state.selectedGap.start_time + '' + state.selectedGap.employee_id;
            } else {
                return null;
            }
        },
        checkoutData(state) {
            return state.checkoutData;
        },
        campaignInitial(state) {
            return state.campaignInitial;
        },
        campaignCode(state) {
            if ('code' in state.campaignCode) {
                return state.campaignCode.code;
            } else {
                return null;
            }
        },
        campaignUuid(state) {
            if ('upcc' in state.campaignCode) {
                return state.campaignCode.upcc;
            } else {
                return null;
            }
        },
        campaignCodeTerms(state) {
            if ('terms' in state.campaignCode) {
                return state.campaignCode.terms;
            } else {
                return null;
            }
        },
        campaignCodeDiscount(state) {
            if ('discount' in state.campaignCode) {
                return state.campaignCode.discount + '%';
            } else {
                return null;
            }
        },
        campaignCodeMessage(state) {
            if ('message' in state.campaignCode) {
                return state.campaignCode.message;
            } else {
                return null;
            }
        },
        widgetFields(state) {
            return state.widgetFields;
        },
        approvedCheckout(state) {
            return state.approvedCheckout;
        },
        startingDate(state) {
            return state.startingDate;
        },
        eveningDay(state) {
            return state.eveningDay;
        },
        checkGaps(state) {
            if ('best_gaps' in state.gaps) {
                return true;
            } else {
                return false;
            }        
        },
        fetchGapEffort(state) {
            return state.fetchGapEffort;
        },
        selectedServiceName(state) {
            return state.selectedServiceName;
        },
        isNewCustomer(state) {
            return state.isNewCustomer;
        },
        supplier(state) {
            return state.supplier;
        },
        selectedServiceDescriptionShort(state) {
            return state.selectedServiceDescriptionShort;
        },
        selectedServiceDescriptionLong(state) {
            return state.selectedServiceDescriptionLong;
        },
        finished(state) {
            return state.finished;
        },
        isBookingPlugin(state) {
            if ('is_booking_plugin' in state.pluginSettings) {
                return state.pluginSettings.is_booking_plugin;
            } else {
                return 0;
            }
        },
        isInternalPlugin(state) {
            if ('is_internal_plugin' in state.pluginSettings) {
                return state.pluginSettings.is_internal_plugin;
            } else {
                return 0;
            }
        },
        fontColor(state) {
            if ('headtitle_text_color' in state.pluginSettings) {
                return state.pluginSettings.headtitle_text_color;
            } else {
                return '#23a994';
            }
        },
        buttonColor(state) {
            if ('button_color' in state.pluginSettings) {
                return state.pluginSettings.button_color;
            } else {
                return '#23a994';
            }
        },
        postalCodeShow(state) {
            if (state.supplierServices.length > 0) {
                return true;
            }  else {
                return false;
            }
        },
        place(state) {
            return state.place;
        },
        personalNumberCheck(state) {
            return state.personalNumberCheck;
        },
        showServiceDescription(state) {
            if ('show_service_description' in state.pluginSettings) {
                return state.pluginSettings.show_service_description;
            } else {
                return 0;
            }
        },
        showFilterDayEvening(state) {
            if ('show_filter_day_evening' in state.pluginSettings) {
                return state.pluginSettings.show_filter_day_evening;
            } else {
                return 0;
            }
        },
        primaryColor(state)
        {
            if ('primary_color' in state.pluginSettings) {
                if (state.pluginSettings.primary_color !== null) {
                    return state.pluginSettings.primary_color;
                }
            }
            return PRIMARY_COLOR;
        },
        primaryMediumColor(state)
        {
            if ('primary_medium_color' in state.pluginSettings) {
                if (state.pluginSettings.primary_medium_color !== null) {
                    return state.pluginSettings.primary_medium_color;
                }
            }
            return PRIMARY_MEDIUM_COLOR;
        },
        validationColor(state)
        {
            if ('validation_color' in state.pluginSettings) {
                if (state.pluginSettings.validation_color !== null) {
                    return state.pluginSettings.validation_color;
                }
            }
            return VALIDATION_COLOR;
        },
        backgroundMainColor(state) {
            if ('background_color' in state.pluginSettings) {
                if (state.pluginSettings.background_color !== null) {
                    return state.pluginSettings.background_color;
                }
            }
            return BACKGROUND_COLOR;
        },
        backgroundPrimaryColor(state) {
            if ('background_primary_color' in state.pluginSettings) {
                if (state.pluginSettings.background_primary_color !== null) {
                    return state.pluginSettings.background_primary_color;
                }
            }
            return BACKGROUND_PRIMARY_COLOR;
        },
        textColor(state) {
            if ('text_color' in state.pluginSettings) {
                if (state.pluginSettings.text_color !== null) {
                    return state.pluginSettings.text_color;
                }
            }
            return TEXT_COLOR;
        },
        backgroundSecondaryColor(state) {
            if ('background_secondary_color' in state.pluginSettings) {
                if (state.pluginSettings.background_secondary_color !== null) {
                    return state.pluginSettings.background_secondary_color;
                }
            }
            return BACKGROUND_SECONDARY_COLOR;
        },
        backgroundLightColor(state) {
            if ('background_light_color' in state.pluginSettings) {
                if (state.pluginSettings.background_light_color !== null) {
                    return state.pluginSettings.background_light_color;
                }
            }
            return BACKGROUND_LIGHT_COLOR;
        },
        highlightColor(state) {
            if ('highlight_color' in state.pluginSettings) {
                if (state.pluginSettings.highlight_color !== null) {
                    return state.pluginSettings.highlight_color;
                }
            }
            return HIGHLIGHT_COLOR;
        }
    }
});
