<template>
  <div v-if="service_time">
    <div class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-2 text-center-bokahem">
      <span class="font-weight-bold-bokahem">{{  service_time }} tim</span> x
      <span class="font-weight-bold-bokahem">{{ employee_quantity }} pers</span>. 
      <span v-if="serviceGroupId == 1">Pris visas inkl rutavdrag.</span>
      <span v-if="serviceGroupId == 2">Pris visas ex moms.</span>
      <br>
      Bästa pris denna vecka: <b>{{ price }}</b> kr.
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    price() {
      if ('best_gaps' in this.$store.getters.gaps) {
        if (this.$store.getters.serviceGroupId == 1) {
          return this.$store.getters.gaps.best_gaps[0].widget_prices.pris;
        } else {
          return this.$store.getters.gaps.best_gaps[0].widget_prices_company.pris_with_discount;
        }
      }
      return null;
    },
    co() {
      if ('best_gaps' in this.$store.getters.gaps) {
        if (this.$store.getters.gaps.best_gaps[0].discounts.travel.value != 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    service_time() {
      if ('best_gaps' in this.$store.getters.gaps) {
          return this.$store.getters.gaps.best_gaps[0].service_time_hours;
      }
      return null;
    },
    employee_quantity() {
      if ('best_gaps' in this.$store.getters.gaps) {
        return this.$store.getters.gaps.best_gaps[0].employee_quantity;
      }
      return null;
    },
    emptySupplier() {
      return this.$store.getters.emptySupplier;
    },
    serviceGroupId() {
      return this.$store.getters.serviceGroupId;
    }
  }
}

</script>