<template>
<div class="row-bokahem no-gutters justify-content-center-bokahem">
    <div class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
        <div class="m-auto-bokahem text-center-bokahem">
            <select @change="onChange($event)" style="min-width:250px;max-width:250px;font-size:20px;height:54px;padding-left: 5px;">
                <option :value="0">
                    Alla anställda
                </option>
                <option :value="employee.id" v-bind:key="index" v-for="(employee,index) in employees">
                    {{ employee.name }}
                </option>
            </select> 
        </div>
    </div>
</div>
</template>

<script>
    import { SET_GAP_EMPLOYEE } from "@/store/mutations.type";

    export default {
        computed: {
            employees() {
              return this.$store.getters.gapEmployees;
            },
        },
        methods: {
            onChange(event) {
                let employeeId = event.target.value;
                this.$store.dispatch(SET_GAP_EMPLOYEE, employeeId);
            }
        }
    }
</script>
