<template>
    <div v-bind:style="{color: textColor + ' !important'}">
        <h2 class="bokahem-h2 widget-subtitle text-dark-gray pb-1" id="headercheckouttop">Ett steg kvar</h2>
        <CheckoutConfirmTop></CheckoutConfirmTop>
        <CheckoutSummary></CheckoutSummary>
        <CheckoutConfirmBottom></CheckoutConfirmBottom>
    </div>
</template>

<script>
    import CheckoutSummary from "@/components/CheckoutSummary";
    import CheckoutConfirmBottom from "@/components/CheckoutConfirmBottom";
    import CheckoutConfirmTop from "@/components/CheckoutConfirmTop";

    export default {
        name: 'App',
        components: {
          CheckoutConfirmTop,
          CheckoutConfirmBottom,
          CheckoutSummary
        },
        computed: {
          textColor() {
            return this.$store.getters.textColor;
          }
        }
    }
</script>