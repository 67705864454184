<template>
        <div class="modal-filter" id="modalFilter" v-if="widgetFields !== null">
            <div class="modal-filter-inner">
                <div class="modal-filter-body" style="font-size: 14px !important;">
                    <h2 class="bokahem-h2 modal-filter-title mb-0">{{ widgetFields.agreement.name }}</h2>
                    <div v-html="widgetFields.agreement.content">
                        {{ widgetFields.agreement.content }}
                    </div>

                    <button type="button"
                            style="color: #FFF"
                            v-bind:style="{backgroundColor: primaryColor }"
                            class="btn-bokahem
                            btn-bokahem-primary-button
                            text-button-color
                            font-weight-semi-bokahem btn-bokahem-lg"
                            @click="closeModal()">OK</button>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        computed: {
            widgetFields() {
                return this.$store.getters.widgetFields;
            },
            primaryColor() {
              return this.$store.getters.primaryColor;
            }
        },
        methods: {
            closeModal() {
                this.$emit('close');
            }
        }
    }
</script>