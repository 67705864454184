<template>
    <div>
        <h2 class="bokahem-h2 widget-subtitle text-dark-gray pt-3 pb-0">Tack! Detta bekräftar din beställning.</h2>
        <div class="bokahem-email-text-finish pb-2">Du har fått bekräftelsen skickad till din mejl. Om du inte ser den vänligen titta i din skräppost.</div>
        <CheckoutSummary></CheckoutSummary>
        <CheckoutClose></CheckoutClose>
    </div>
</template>

<script>
    import CheckoutSummary from "@/components/CheckoutSummary"
    import CheckoutClose from "@/components/CheckoutClose"

    export default {
        name: 'App',
        components: {
            CheckoutSummary,
            CheckoutClose
        }
    }
</script>