<template>
    <div class="row-bokahem no-gutters justify-content-center-bokahem">
        <div class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
            
            <div class="bokahem-text-decoration-underline col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width text-center-bokahem">
                <a @click="openFilter()" v-show="filterOpened === false" style="font-size:20px;cursor:pointer;">
                Visa filter
                </a>
                <a @click="openFilter()" v-show="filterOpened === true"  style="font-size:20px;cursor:pointer;">
                Stäng filter
                </a>
            </div>
    
            <div class="m-auto-bokahem text-center-bokahem" v-show="filterOpened === true">
                <div class="checkbox-label-row-bokahem" style="padding-top: 0px;padding-bottom:5px;" 
                v-bind:key="index"
                v-for="(employee,index) in employees">
                    <label class="common-checkbox-label-bokahem mr-1" 
                        :class="{'common-checkbox-label-bokahem_active': employee.selected === true }">
                        <input type="radio" class="common-checkbox" id="callMessage">
                        <span class="common-custom-checkbox" @click="setEmployee(employee.id)">
                        <span class="common-custom-checkbox_after"></span>
                        </span>
                    </label>
                    <label v-if="employee.count == 0" for="callMessage" class="mr-1 call-message-label checkbox-label_col-bokahemor font-weight-medium-bokahem">
                        {{ employee.full_name }} 
                    </label>
                    <label v-if="employee.count > 0" style="font-weight:bold;" for="callMessage" class="mr-1 call-message-label checkbox-label_col-bokahemor font-weight-medium-bokahem">
                        {{ employee.full_name }}
                    </label>
                    <span style="font-size:12px;">
                        <span v-if="employee.bookings >= 1">
                            {{ employee.bookings }} {{ employee.description }}  
                        </span>
                        <span v-if="employee.bookings < 1">
                            Inga bokningar
                        </span>
                        <span style="font-weight:bold" v-if="employee.count > 0">
                        / Luckor: {{ employee.count }}
                        </span>
                    </span>
                </div>
            <div class="col-bokahem-6 m-auto-bokahem mb-1">
                <a style="cursor:pointer;text-decoration:underline;" @click="clearAllEmployeeFilter()">
                    Rensa anställda
                </a>
            </div>   
            <div class="col-bokahem-6 m-auto-bokahem mb-2">
                <a style="cursor:pointer;text-decoration:underline;" @click="selectAllEmployeeFilter()">
                    Välj alla anställda
                </a>
            </div>   
            <div class="col-bokahem-6 m-auto-bokahem">
                <button @mouseover="hover = true" @mouseleave="hover = false" :class="{ 'btn-hover': hover }"
                    v-bind:style="{'background-color': buttonColor + ' !important'}" style="height:54px;" @click="filterGapByEmployees()" 
                    class="btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem h-bokahem-100">
                    Filtrera anställda
                </button>
            </div>    
        </div>
    </div>
</div>
</template>
    
    <script>
    
        import { 
            SET_EMPLOYEE, 
            CLEAR_ALL_EMPLOYEE_FILTER, 
            SELECT_ALL_EMPLOYEE_FILTER
        } from "@/store/mutations.type";

        import { FETCH_GAPS } from "@/store/actions.type";
    
        export default {
            computed: {
                employees() {
                    return this.$store.getters.employees;
                },
                buttonColor() {
                    return this.$store.getters.primaryMediumColor;
                },
            },
            data() {
                return {
                    filterOpened: false,
                    hover: false
                };
            },
            methods: {
                clearAllEmployeeFilter() {
                    this.$store.dispatch(CLEAR_ALL_EMPLOYEE_FILTER);
                },
                selectAllEmployeeFilter() {
                    this.$store.dispatch(SELECT_ALL_EMPLOYEE_FILTER);
                },
                setEmployee(employeeId) {
                    this.$store.dispatch(SET_EMPLOYEE, employeeId);
                },
                openFilter() {
                    this.filterOpened = !this.filterOpened;
                },
                filterGapByEmployees() {
                  
                    let params = {};
    
                    let serviceParameter = {};
    
                    if (this.$store.getters.serviceGroupId == 2) {
                        serviceParameter['bathroom'] = this.$store.getters.bathroom;
                        if (this.$store.state.kitchen === true) {
                        serviceParameter['kitchen'] = 1;
                        } else {
                        serviceParameter['kitchen'] = 0;
                        }
                    }
    
                    if (this.$store.getters.additionalParameter == true) {
    
                    if (this.$store.getters.bars === true) {
                        serviceParameter['bars'] = 1;
                    } else {
                        serviceParameter['bars'] = 0;
                    }
    
                    if (this.$store.getters.frames === true) {
                        serviceParameter['frames'] = 1;
                    } else {
                        serviceParameter['frames'] = 0;
                    }
    
                    if (this.$store.getters.balcony === true) {
                        serviceParameter['balcony'] = 1;
                    } else {
                        serviceParameter['balcony'] = 0;
                    }
    
                    if (this.$store.getters.ladder === true) {
                        serviceParameter['ladder'] = 1;
                    } else {
                        serviceParameter['ladder'] = 0;
                    }
    
                    if (this.$store.getters.fourSides === true) {
                        serviceParameter['4sides'] = 1;
                    } else {
                        serviceParameter['4sides'] = 0;
                    }
    
                    serviceParameter['extra_windows'] = this.$store.state.extraWindows;
    
                    if (this.$store.getters.extraWindows > 0) {
                        serviceParameter['is_extra_windows'] = 1;
                    } else {
                        serviceParameter['is_extra_windows'] = 0;
                    }
                    }
    
                    serviceParameter['square_meters'] = this.$store.getters.kvm;
    
                    params['service_id'] = this.$store.getters.selectedServiceId;
                    params['postal_code'] = this.$store.getters.postalCode;
                    params['service_parameters'] = serviceParameter;
                    params['service_category_id'] = this.$store.getters.serviceCategoryId;
                    params['service_group_id'] = this.$store.getters.serviceGroupId;
    
                    params['supplier_id'] = this.$store.getters.supplierId;
                    params['cleanEffort'] = true;
                    params['showSupplier'] = true;
                    params['check_supplier'] = true;
                    params['starting_date'] = this.$store.getters.startingDate;
                    params['time_filter'] = this.$store.getters.eveningDay;
    
                    /*
                    state.selectedEmployees.forEach((selectedEmployee) => {
                        if (unselectedEmployeeId != selectedEmployee) {
                            selectedEmployees[selectedEmployee] = selectedEmployee;
                        }
                    });
                    */
    
                    let selectedEmployees = this.$store.state.selectedEmployees;
                    let employeeIds = selectedEmployees.join(',');
                    params['employee_ids'] = employeeIds;
    
                    this.$store.dispatch(FETCH_GAPS, params);
                }
            }
        }
    </script>
    