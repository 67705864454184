import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "@/common/config";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
},
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  }
};

export default ApiService;

/**
 *
 * @type {{get(): *}}
 */
export const PluginSettingsService = {
    get() {
        return ApiService.get('pluginsettings', '');
    }
};

/**
 *
 * @type {{get(): *}}
 */
export const SupplierServicesService = {
    get(params) {
        return ApiService.post('supplierservices', params);
    }
};

export const CampaignCodeService = {
    get(params) {
        return ApiService.post('campaigncode', params);
    },
};

/**
 * Method to get suppliers
 */
export const SuppliersService = {
    get(params) {
        return ApiService.post("suppliers", params);
    }
};

/**
 * Method to get areas
 */
export const AreasService = {
    get(params) {
        return ApiService.post("areas-by-postal-code", params);
    }
};

/**
 * Method to get statistical feed data
 */
export const CreateBookingService = {
    get(params) {
        return ApiService.post("booking", params);
    }
};

export const IsNewCustomerService = {
    get(params) {
        return ApiService.post("is-new-customer", params);
    },
};

/**
 *
 * @type {{get(): *}}
 */
export const SendInterestFormService = {
    get(params) {
        return ApiService.post('interestform', params);
    }
};

/**
 * Method to get widget fields
 */
export const WidgetFieldsService = {
    get() {
        return ApiService.get('widget-fields');
    }
};

/**
 * Method to get gaps
 */
export const GapsService = {
    get(params) {
        return ApiService.post("gaps", params);
    },
};

/**
 *
 * @type {{get(*=): *}}
 */
export const WindowQuantityService = {
    get(kvm) {
        return ApiService.post('windowquantity', {
            kvm: kvm
        });
    }
};

/**
 *
 * @type {{get(*=): *}}
 */
export const CheckPostalCodeService = {
    get(postalCode) {
        return ApiService.post('postalcode/check', {
            postal_code: postalCode
        });
    }
};

export const CheckPersonalNumberService = {
    get(personalNumber) {
        let query = '?number=' + personalNumber;
        return ApiService.get('validate-number', query);
    },
};

export const FetchPersonalNumberService = {
    get(personalNumber) {
        let query = '?number=' + personalNumber;
        return ApiService.get('number', query);
    },
};
