<template>
  <div class="breadcrumb-bokahem">
    <div style="display: inline-flex; padding: 0;" class="label-group-list-bokahem">
      <label class="common-checkbox-label-bokahem"
             :class="{'common-radio-label_active': eveningDay == 1 }"
             style="min-height: auto;align-items:baseline !important;margin-right:5px;">
        <span @click="setUpEveningDay(1)" class="common-custom-radio" style="width: 20px;height: 20px;max-width: 20px;margin-right:5px;">
              <input type="radio" class="common-radio">
           <span class="common-custom-radio_after"></span>
        </span>
        <span v-bind:style="{color:textColor + ' !important'}">Dag</span>
      </label>
      <label class="common-checkbox-label-bokahem" :class="{'common-radio-label_active': eveningDay == 2 }"
             style="min-height: auto;align-items:baseline !important;margin-right:5px;">
        <span @click="setUpEveningDay(2)" class="common-custom-radio" style="width: 20px;height: 20px;max-width: 20px;margin-right:5px;">
              <input type="radio" class="common-radio">
           <span class="common-custom-radio_after"></span>
        </span>
        <span v-bind:style="{color:textColor + ' !important'}">Kväll</span>
      </label>
    </div>
  </div>
</template>

<script>
    import {SET_UP_EVENING_DAY} from "@/store/mutations.type";
    export default {
      methods: {
        setUpEveningDay(eveningDay) {
          this.$store.dispatch(SET_UP_EVENING_DAY, eveningDay);
        },
      },
      watch: {
        '$store.state.eveningDay': function() {
          this.eveningDay = this.$store.state.eveningDay;
        },
      },
      computed: {
        isLoading() {
          return this.$store.getters.isLoading;
        },
        eveningDay() {
          return this.$store.getters.eveningDay;
        },
        textColor() {
          return this.$store.getters.textColor;
        }
      }
    }
</script>