<template>
  <div v-if="selectedServiceName && showServiceDescription == 1">
    <h3 v-bind:style="{color: primaryColor + ' !important'}" class="bokahem-h3 col-bokahem-12 font-weight-bold m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
      {{ selectedServiceName }}
    </h3>

    <div v-if="longOpen === false" class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
      <span v-html="short" v-if="short && short != '-'"></span>
      <a v-if="short && short != '-'"  href="javascript:void(0)" v-bind:style="{color: primaryMediumColor + ' !important'}" @click="openDescription()" class="btn-bokahem-link text-primary">
        Läs mer
      </a>
    </div>

    <div v-if="longOpen === true" class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
      <span v-html="long"></span>
      <a href="javascript:void(0)" v-bind:style="{color: primaryMediumColor + ' !important'}" @click="openDescription()" class="btn-bokahem-link text-primary">
        Stäng
      </a>
    </div>

  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      long: null,
      short: null,
      longOpen: false
    }
  },
  watch: {
    '$store.state.selectedServiceDescriptionLong': function () {
        this.long = this.$store.state.selectedServiceDescriptionLong;
    },
    '$store.state.selectedServiceDescriptionShort': function () {
        this.short = this.$store.state.selectedServiceDescriptionShort;
    },
  },
  computed: {
    selectedServiceName() {
      return this.$store.getters.selectedServiceName;
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    primaryMediumColor() {
      return this.$store.getters.primaryMediumColor;
    },
    showServiceDescription() {
      return this.$store.getters.showServiceDescription;
    }
  },
  methods: {
    openDescription() {
      this.longOpen = !this.longOpen;
    }
  }
}

</script>