<template>
    <div>

      <h2 v-if="formattedDays && isLoading === false" class="widget-subtitle mb-0 mt-1
      bokahem-best-gap-title
      widget-subtitle_sm
      bokahem-h2
      font-weight-semi-bokahem
      text-primary
      letter-spacing_small" v-bind:style="{ color: primaryColor + ' !important'}">
        {{ supplierPageMoreGapsText }}
      </h2>

      <GapShortSummary v-if="isLoading === false"></GapShortSummary>
    
      <div class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
        <HeaderSearchDate v-if="formattedDays && isLoading === false"></HeaderSearchDate>
        <HeaderFilterEvening v-if="formattedDays && isLoading === false && showFilterDayEvening == 1"></HeaderFilterEvening>
        <EmployeeSelection v-if="isInternalPlugin == 1 && isLoading === false"></EmployeeSelection>
      </div>

        <div class="full-days-gaps-bokahem">
          <div class="calendar-bokahem" v-if="formattedDays && isLoading === false">
            <div class="calendar-bokahem-title-holder mb-1">
              <div @click="getGapsLeft()" v-if="formattedDays.previous_week_start_date"
                      v-bind:style="{ backgroundColor: backgroundMainColor, borderRightColor: primaryMediumColor + ' !important'}"
                      class="calendar-bokahem-arrow calendar-bokahem-arrow-left"></div>
              <h4 class="bokahem-h4 calendar-bokahem-title">{{ formattedDays.start_week }}-{{ formattedDays.end_week }}</h4>

              <div @click="getGapsRight()" v-if="formattedDays.next_week_start_date"
                      v-bind:style="{ backgroundColor: backgroundMainColor, borderLeftColor: primaryMediumColor + ' !important'}"
                      class="calendar-bokahem-arrow calendar-bokahem-arrow-right"></div>
            </div>

            <GapEmployeeSelection v-if="isInternalPlugin == 1 && isLoading === false">
            </GapEmployeeSelection>

            <div class="calendar-bokahem-body" style="max-width:760px">
              <div class="calendar-bokahem-day-bokahem-holder"
                   v-if="allGaps">
                    <span class="calendar-bokahem-day-bokahem"
                          v-bind:style="{ backgroundColor: primaryMediumColor + ' !important'}"
                          v-bind:key="index" :class="{ 'bokahem-text-decoration-underline': item.date == startingDate }"
                          v-for="(item,index) in formattedDays.all_days">
                        {{ item.day_of_week }} {{ item.day }}
                    </span>
              </div>

              <div class="calendar-bokahem-list" v-if="allGaps && isLoading === false">
                <div class="calendar-bokahem-row" v-bind:key="indexDay"
                     v-for="(itemDay,indexDay) in allGaps">
                  <div class="calendar-bokahem-full-list-item-holder"
                       v-bind:key="gapKey"
                       @click="selectGap(gap.start_date, gap.start_time, gap.end_time, gap.employee_id)"
                       v-for="(gap,gapKey) in itemDay">
                    <div v-if="filterGap(gap, selectedGapEmployeeId) === true">
                  
                      <div class="calendar-bokahem-list-item border-rounded-bokahem calendar-bokahem-list-item-active"
                           v-bind:style="{ backgroundColor: checkGap(gap) + ' !important', color: textColor + ' !important', borderColor: primaryColor + ' !important'}"
                           :class="{ 'calendar-bokahem-list-item-best': gap.best_gap === true && selectedGapKey == null,
                                     'calendar-bokahem-list-item-selected': gap.start_date + '' + gap.start_time + '' + gap.employee_id == selectedGapKey}">
                        <time class="calendar-bokahem-time">{{ gap.start_time }}</time>
                        <span class="calendar-bokahem-price" v-if="serviceGroupId == 1">
                                      {{ gap.widget_prices.pris | toCurrency }}
                                    </span>
                        <span class="calendar-bokahem-price" v-if="serviceGroupId == 2">
                                      {{ gap.widget_prices_company.pris_with_discount | toCurrency }}
                                    </span>
                        <ul class="calendar-bokahem-list-icon">

                          <li class="bokahem-gap-number-co-icon calendar-bokahem-list-icon-item text-left bokahem-gray-text"
                              v-if="isInternalPlugin == 1 && gap.discounts.travel.value != 0">
                            {{ gap.priority_number }}
                          </li>

                          <li class="bokahem-gap-number-icon calendar-bokahem-list-icon-item text-left bokahem-gray-text"
                              v-if="isInternalPlugin == 1 && gap.discounts.travel.value == 0">
                            {{ gap.priority_number }}
                          </li>

                          <li class="calendar-bokahem-list-icon-item" v-if="gap.discounts.travel.value != 0">
                            <svg class="icon-svg icon-co2 bokahem-main-icon-color-svg" style="background-color: unset !important;">
                              <CoIcon></CoIcon>
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div v-if="filterGap(gap, selectedGapEmployeeId) === false">
                      <div
                          class="calendar-bokahem-list-item border-rounded-bokahem calendar-bokahem-list-item-empty">
                        <ul class="calendar-bokahem-list-icon">
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="part-days-gaps-bokahem">
          <div class="calendar-bokahem" v-if="formattedDays && isLoading === false">
            <div class="calendar-bokahem-title-holder mb-1">
              <div @click="getGapsLeft()" v-if="formattedDays.previous_week_start_date"
                      v-bind:style="{ backgroundColor: backgroundMainColor, borderRightColor: primaryMediumColor + ' !important'}"
                      class="calendar-bokahem-arrow calendar-bokahem-arrow-left"></div>
              <h4 class="bokahem-h4 calendar-bokahem-title">{{ formattedDays.start_week }}-{{ formattedDays.end_week }}</h4>
              <div @click="getGapsRight()" v-if="formattedDays.next_week_start_date"
                      v-bind:style="{ backgroundColor: backgroundMainColor, borderLeftColor: primaryMediumColor + ' !important'}"
                      class="calendar-bokahem-arrow calendar-bokahem-arrow-right"></div>
            </div>
            <div class="calendar-bokahem-body">
              <div class="calendar-bokahem-day-bokahem-holder"
                   v-if="(formattedDays && calendarGaps) || (!calendarGaps && !weekendGaps)">
                    <span class="calendar-bokahem-day-bokahem"
                          v-bind:style="{ backgroundColor: primaryMediumColor + ' !important'}"
                          v-bind:key="index" :class="{ 'bokahem-text-decoration-underline': item.date == startingDate }"
                          v-for="(item,index) in formattedDays.days">
                        {{ item.day_of_week }} {{ item.day }}
                    </span>
              </div>

              <div class="calendar-bokahem-list" v-if="calendarGaps && isLoading === false">
                <div class="calendar-bokahem-row" v-bind:key="indexDay"
                     v-for="(itemDay,indexDay) in calendarGaps">
                  <div class="calendar-bokahem-list-item-holder"
                       v-bind:key="gapKey"
                       @click="selectGap(gap.start_date, gap.start_time, gap.end_time, gap.employee_id)"
                       v-for="(gap,gapKey) in itemDay">
                    <div v-if="filterGap(gap, selectedGapEmployeeId) === true">
                      <div class="calendar-bokahem-list-item border-rounded-bokahem calendar-bokahem-list-item-active"
                           v-bind:style="{ backgroundColor: checkGap(gap) + ' !important', color: textColor + ' !important', borderColor: primaryColor + ' !important'}"
                           :class="{ 'calendar-bokahem-list-item-best': gap.best_gap === true && selectedGapKey == null,
                                     'calendar-bokahem-list-item-selected': gap.start_date + '' + gap.start_time + '' + gap.employee_id == selectedGapKey}">
                        <time class="calendar-bokahem-time">{{ gap.start_time }}</time>
                        <span class="calendar-bokahem-price" v-if="serviceGroupId == 1">
                                      {{ gap.widget_prices.pris | toCurrency }}
                                    </span>
                        <span class="calendar-bokahem-price" v-if="serviceGroupId == 2">
                                      {{ gap.widget_prices_company.pris_with_discount | toCurrency }}
                                    </span>
                        <ul class="calendar-bokahem-list-icon">

                          <li class="bokahem-gap-number-co-icon calendar-bokahem-list-icon-item text-left bokahem-gray-text"
                              v-if="isInternalPlugin == 1 && gap.discounts.travel.value != 0">
                            {{ gap.priority_number }}
                          </li>

                          <li class="bokahem-gap-number-icon calendar-bokahem-list-icon-item text-left bokahem-gray-text"
                              v-if="isInternalPlugin == 1 && gap.discounts.travel.value == 0">
                            {{ gap.priority_number }}
                          </li>

                          <li class="calendar-bokahem-list-icon-item" v-if="gap.discounts.travel.value != 0">
                            <svg class="icon-svg icon-co2 bokahem-main-icon-color-svg" style="background-color: unset !important;">
                              <CoIcon></CoIcon>
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div v-if="filterGap(gap,selectedGapEmployeeId) === false">
                      <div
                          class="calendar-bokahem-list-item border-rounded-bokahem calendar-bokahem-list-item-empty">
                        <ul class="calendar-bokahem-list-icon">
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="calendar-bokahem calendar-bokahem_small mb-3" v-if="weekendGaps
        && formattedDays
        && 'weekend_days' in formattedDays
        && isLoading === false">
            <div class="calendar-bokahem-body">

              <div class="calendar-bokahem-day-bokahem-holder">
                    <span class="calendar-bokahem-day-bokahem"
                          v-bind:style="{ backgroundColor: primaryMediumColor + ' !important'}"
                          v-bind:key="index" :class="{ 'bokahem-text-decoration-underline': item.date == startingDate }"
                          v-for="(item,index) in formattedDays.weekend_days">
                        {{ item.day_of_week }} {{ item.day }}
                    </span>
              </div>

              <div class="calendar-bokahem-list"  v-if="weekendGaps && isLoading === false">
                <div class="calendar-bokahem-row" v-bind:key="indexDay"
                     v-for="(itemDay,indexDay) in weekendGaps">
                  <div class="calendar-bokahem-list-item-holder"
                       v-bind:key="gapKey"
                       @click="selectGap(gap.start_date, gap.start_time, gap.end_time, gap.employee_id)"
                       v-for="(gap,gapKey) in itemDay">
                    <div v-if="filterGap(gap) === true">
                      <div class="calendar-bokahem-list-item border-rounded-bokahem calendar-bokahem-list-item-active"
                           v-bind:style="{ backgroundColor: checkGap(gap) + ' !important', color: textColor + ' !important', borderColor: primaryColor + ' !important'}"
                           :class="{ 'calendar-bokahem-list-item-best': gap.best_gap === true && selectedGapKey == null,
                                     'calendar-bokahem-list-item-selected': gap.start_date + '' + gap.start_time + '' + gap.employee_id == selectedGapKey}">
                        <time class="calendar-bokahem-time">{{ gap.start_time }}</time>
                        <span class="calendar-bokahem-price" v-if="serviceGroupId == 1">
                      {{ gap.widget_prices.pris | toCurrency }}
                    </span>
                        <span class="calendar-bokahem-price" v-if="serviceGroupId == 2">
                      {{ gap.widget_prices_company.pris_with_discount | toCurrency }}
                    </span>

                        <ul class="calendar-bokahem-list-icon">

                          <li style="margin-left:-30px;margin-right:12px;font-style:italic" class="calendar-bokahem-list-icon-item text-left bokahem-gray-text"
                              v-if="isInternalPlugin == 1 && gap.discounts.travel.value != 0">
                            {{ gap.priority_number }}
                          </li>

                          <li style="margin-left:-60px;font-style:italic" class="calendar-bokahem-list-icon-item text-left bokahem-gray-text"
                              v-if="isInternalPlugin == 1 && gap.discounts.travel.value == 0">
                            {{ gap.priority_number }}
                          </li>

                          <li class="calendar-bokahem-list-icon-item" v-if="gap.discounts.travel.value != 0">
                            <svg class="icon-svg icon-co2 bokahem-main-icon-color-svg" style="background-color: unset !important;">
                              <CoIcon></CoIcon>
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <GapIconExplanation></GapIconExplanation>
    </div>
</template>

<script>

    import CoIcon from '@/assets/custom-svg/icons/co2-version2.svg';
    import GapShortSummary from "@/components/GapShortSummary";
    import HeaderSearchDate from "@/components/HeaderSearchDate";
    import GapIconExplanation from "@/components/GapIconExplanation";
    import EmployeeSelection from "@/components/EmployeeSelection";
    import HeaderFilterEvening from "@/components/HeaderFilterEvening";
    import {SELECT_GAP, FETCH_GAPS} from "@/store/actions.type";
    import {SET_STARTING_DATE} from "@/store/mutations.type";
    import GapEmployeeSelection from './GapEmployeeSelection.vue';
    
  //  import {WINDOW_CLEANING_SERVICE_CATEGORY_ID} from "@/store/constants.type";

    export default {
        components: {
    CoIcon,
    GapShortSummary,
    GapIconExplanation,
    HeaderSearchDate,
    HeaderFilterEvening,
    EmployeeSelection,
    GapEmployeeSelection
},
        methods: {
            filterGap(gap, selectedGapEmployeeId = null) {
              
              if (gap === null) {
                return false;
              }

              if (selectedGapEmployeeId === null) {
                return true;
              }

              if (selectedGapEmployeeId == '0' || selectedGapEmployeeId == 0) {
                return true;
              }

              if (selectedGapEmployeeId !== null && selectedGapEmployeeId !== 0) {
                if (gap.employee.id == selectedGapEmployeeId) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }

              /*
              if (gap.best_gap == true) {
                let params = {
                  'startDate': gap.start_date,
                  'startTime': gap.start_time,
                  'endTime': gap.end_time,
                  'employeeId': gap.employee_id
                };
                this.$store.dispatch(SELECT_GAP, params);
              }
              */
            },
            selectGap(startDate, startTime, endTime, employeeId) {
                let params = {
                    'startDate': startDate,
                    'startTime': startTime,
                    'endTime': endTime,
                    'employeeId': employeeId
                };

                this.$store.dispatch(SELECT_GAP, params).then(() => {
                  /*
                  let params = {};
                  params['a'] = this.$store.state.selectedGap.travel_data_old.points.ab.origin_postal;
                  params['b'] = this.$store.state.selectedGap.travel_data_old.points.ab.destination_postal;
                  params['c'] = this.$store.state.selectedGap.travel_data_old.points.ac.destination_postal;
                  this.$store.dispatch(FETCH_MAP, params);
                  */
                });

                if (this.$store.getters.isInternalPlugin == 1) {
                  this.$scrollTo('#gapsummaryinternal');
                } else {
                  this.$scrollTo('#gapsummary');
                }
            },
            getGapsLeft() {

                if ('formatted_days' in this.$store.getters.gaps
                    && 'previous_week_start_date' in this.$store.getters.gaps.formatted_days
                    && 'previous_week_end_date' in this.$store.getters.gaps.formatted_days
                    && this.$store.getters.gaps.formatted_days.previous_week_start_date
                    && this.$store.getters.gaps.formatted_days.previous_week_end_date) {

                    let startingDate = this.$store.getters.gaps.formatted_days.previous_week_start_date;
                    startingDate = new Date(startingDate);

                    let todayDate = new Date();
                    if (startingDate < todayDate) {
                      startingDate = todayDate;
                    }

                    this.$store.dispatch(SET_STARTING_DATE, startingDate);

                    let params = {};
                    let serviceParameter = {};

                  if (this.$store.getters.additionalParameter == true) {

                    if (this.$store.getters.bars === true) {
                      serviceParameter['bars'] = 1;
                    } else {
                      serviceParameter['bars'] = 0;
                    }

                    if (this.$store.getters.frames === true) {
                      serviceParameter['frames'] = 1;
                    } else {
                      serviceParameter['frames'] = 0;
                    }

                    if (this.$store.getters.balcony === true) {
                      serviceParameter['balcony'] = 1;
                    } else {
                      serviceParameter['balcony'] = 0;
                    }

                    if (this.$store.getters.ladder === true) {
                      serviceParameter['ladder'] = 1;
                    } else {
                      serviceParameter['ladder'] = 0;
                    }

                    if (this.$store.getters.fourSides === true) {
                      serviceParameter['4sides'] = 1;
                    } else {
                      serviceParameter['4sides'] = 0;
                    }

                    serviceParameter['extra_windows'] = this.$store.getters.extraWindows;

                    if (this.$store.getters.extraWindows > 0) {
                      serviceParameter['is_extra_windows'] = 1;
                    } else {
                      serviceParameter['is_extra_windows'] = 0;
                    }
                  }

                    if (this.$store.getters.serviceGroupId == 2) {
                      serviceParameter['bathroom'] = this.$store.getters.bathroom;
                      serviceParameter['kitchen'] = this.$store.getters.kitchen;
                    }

                    serviceParameter['square_meters'] = this.$store.getters.kvm;

                    params['service_id'] =  this.$store.getters.selectedServiceId;
                    params['supplier_id'] = this.$store.getters.supplierId;
                    params['service_parameters'] = serviceParameter;
                    params['postal_code'] = this.$store.getters.postalCode;
                    params['start_date'] = this.$store.getters.gaps.formatted_days.previous_week_start_date;
                    params['end_date'] = this.$store.getters.gaps.formatted_days.previous_week_end_date;

                 //   params['cleanEffort'] = true;
                    params['set_effort'] = 3;

                    if (this.$store.getters.selectedServiceId
                        && this.$store.getters.supplierId
                        && this.$store.getters.postalCode
                        && this.$store.getters.kvm) {
                        this.$store.dispatch(FETCH_GAPS, params);
                    }
                } else {
                    return;
                }
            },
            checkGap(gap) {
                if (gap.best_gap === true && this.$store.getters.selectedGapKey == null) {
                    return this.$store.getters.highlightColor;
                } else if (gap.start_date + '' + gap.start_time + '' + gap.employee_id == this.$store.getters.selectedGapKey) {
                    return this.$store.getters.highlightColor
                } else {
                    return this.$store.getters.backgroundLightColor;
                }
            },
            getGapsRight() {

                if ('formatted_days' in this.$store.getters.gaps
                    && 'next_week_start_date' in this.$store.getters.gaps.formatted_days
                    && 'next_week_end_date' in this.$store.getters.gaps.formatted_days
                    && this.$store.getters.gaps.formatted_days.next_week_start_date
                    && this.$store.getters.gaps.formatted_days.next_week_end_date) {

                    let startingDate = this.$store.getters.gaps.formatted_days.next_week_start_date;
                    startingDate = new Date(startingDate);

                    let todayDate = new Date();
                    if (startingDate < todayDate) {
                      startingDate = todayDate;
                    }

                    this.$store.dispatch(SET_STARTING_DATE, startingDate);

                    let params = {};
                    let serviceParameter = {};


                  if (this.$store.getters.additionalParameter == true) {

                    if (this.$store.getters.bars === true) {
                      serviceParameter['bars'] = 1;
                    } else {
                      serviceParameter['bars'] = 0;
                    }

                    if (this.$store.getters.frames === true) {
                      serviceParameter['frames'] = 1;
                    } else {
                      serviceParameter['frames'] = 0;
                    }

                    if (this.$store.getters.balcony === true) {
                      serviceParameter['balcony'] = 1;
                    } else {
                      serviceParameter['balcony'] = 0;
                    }

                    if (this.$store.getters.ladder === true) {
                      serviceParameter['ladder'] = 1;
                    } else {
                      serviceParameter['ladder'] = 0;
                    }

                    if (this.$store.getters.fourSides === true) {
                      serviceParameter['4sides'] = 1;
                    } else {
                      serviceParameter['4sides'] = 0;
                    }

                    serviceParameter['extra_windows'] = this.$store.getters.extraWindows;

                    if (this.$store.getters.extraWindows > 0) {
                      serviceParameter['is_extra_windows'] = 1;
                    } else {
                      serviceParameter['is_extra_windows'] = 0;
                    }
                  }

                    if (this.$store.getters.serviceGroupId == 2) {
                      serviceParameter['bathroom'] = this.$store.getters.bathroom;
                      serviceParameter['kitchen'] = this.$store.getters.kitchen;
                    }

                    serviceParameter['square_meters'] = this.$store.getters.kvm;

                    params['service_id'] = this.$store.getters.selectedServiceId;
                    params['supplier_id'] = this.$store.getters.supplierId;
                    params['service_parameters'] = serviceParameter;
                    params['postal_code'] = this.$store.getters.postalCode;
                    params['start_date'] = this.$store.getters.gaps.formatted_days.next_week_start_date;
                    params['end_date'] = this.$store.getters.gaps.formatted_days.next_week_end_date;
                    params['set_effort'] = 3;

                    if (this.$store.getters.selectedServiceId
                        && this.$store.getters.supplierId
                        && this.$store.getters.postalCode
                        && this.$store.getters.kvm) {
                        this.$store.dispatch(FETCH_GAPS, params);
                    }

                } else {
                    return;
                }
            }
        },
        computed: {
            selectedGapEmployeeId() {
              return this.$store.getters.selectedGapEmployeeId;
            },
            selectedGapKey() {
              return this.$store.getters.selectedGapKey;
            },
            isInternalPlugin() {
              return this.$store.getters.isInternalPlugin;
            },
            supplierPageMoreGapsText() {
              return 'Välj tid som passar dig';
            },
            serviceGroupId() {
              return this.$store.getters.serviceGroupId;
            },
            startingDate() {
              let year = this.$store.getters.startingDate.getFullYear();
              let month = this.$store.getters.startingDate.getMonth() + 1;
            //  let day = this.$store.getters.startingDate.getDate();
              var day = (this.$store.getters.startingDate.getDate() < 10 ? '0' : '') + this.$store.getters.startingDate.getDate();

              let dateString = year + '-' + month + '-' + day;

              return dateString;
            },
            formattedDays() {
                if ('formatted_days' in this.$store.getters.gaps) {
                    if ('previous_week_start_date' in this.$store.getters.gaps.formatted_days
                        && 'previous_week_end_date' in this.$store.getters.gaps.formatted_days
                        && 'next_week_start_date' in this.$store.getters.gaps.formatted_days
                        && 'next_week_end_date' in this.$store.getters.gaps.formatted_days) {
                        return this.$store.getters.gaps.formatted_days;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
            calendarGaps() {
                if (this.$store.getters.gaps && 'calendar_gaps' in this.$store.getters.gaps) {

                    if ('0' in this.$store.getters.gaps.calendar_gaps) {
                        return this.$store.getters.gaps.calendar_gaps.filter(gap => {
                            return gap;
                        })
                    } else {
                        return null;
                    }

                } else {
                    return null;
                }
            },
            allGaps() {
              if (this.$store.getters.gaps && 'all_gaps' in this.$store.getters.gaps) {

                if ('0' in this.$store.getters.gaps.all_gaps) {
                  return this.$store.getters.gaps.all_gaps.filter(gap => {
                    return gap;
                  })
                } else {
                  return null;
                }

              } else {
                return null;
              }
            },
            weekendGaps() {
              if (this.$store.getters.gaps && 'weekend_gaps' in this.$store.getters.gaps) {
                if ('0' in this.$store.getters.gaps.weekend_gaps) {
                  return this.$store.getters.gaps.weekend_gaps.filter(gap => {
                    return gap;
                  })
                } else {
                  return null;
                }

              } else {
                return null;
              }
            },
            isLoading()
            {
                return this.$store.getters.isLoading;
            },
            primaryColor()
            {
              return this.$store.getters.primaryColor;
            },
            primaryMediumColor()
            {
              return this.$store.getters.primaryMediumColor;
            },
            textColor()
            {
              return this.$store.getters.textColor;
            },
            showFilterDayEvening()
            {
              return this.$store.getters.showFilterDayEvening;
            }
        }
    }
</script>
