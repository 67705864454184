<template>
    <div class="container-bokahem">
        <div class="row-bokahem">
            <div class="col-bokahem-11 col-bokahem-md-6 m-auto mb-1">
                <button @click="closeCheckout()" type="button" class="btn-bokahem btn-bokahem-primary-button text-button-color border-rounded-bokahem btn-bokahem-lg font-weight-bold-bokahem">STÄNG</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        methods: {
            closeCheckout() {
              window.location.reload();
            }
        }
    }
</script>